import { SET_ONBOARDING_NEEDED, ADD_TO_USER_HISTORY } from "./onboardingConstants"

export function setOnboardingNeeded(value) {
    return {
        type: SET_ONBOARDING_NEEDED,
        payload: value
    }   
}

export function addToUserHistory(uid) {


    return {
        type: ADD_TO_USER_HISTORY,
        payload: uid
    }   
}

