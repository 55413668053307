import React from 'react';
import { Menu, Label } from 'semantic-ui-react';
import { Link, useHistory } from 'react-router-dom';
import { clearNotes } from '../notes/noteActions';
import { useDispatch } from 'react-redux';
import { overdueCount } from '../../app/common/util/util';
import { clearSelectedTopic } from './topicActions';
import { closeSidebar } from '../sidebar/sidebarReducer';
import { consoleLog, logEvent } from '../../app/common/logging/logging';

export default function TopicListItem({ topic, active }) {
  const history = useHistory();
  const dispatch = useDispatch();


  function moveToNewTopic(provider) {
    
    logEvent("Tapped List Topic Cell Tapped", {topic_id: topic.id, topic_name: topic.name})

    // Don't do anything if this is already active
    if (active) {
      return;
    }

    dispatch(clearSelectedTopic());
    dispatch(clearNotes());

    if (window.innerWidth < 560) {
      dispatch(closeSidebar())
    }

    if (topic.shared_id) {
      history.push(`/t/${topic.shared_id}`);
    } else {
      history.push(`/t/${topic.id}`);
    }


  }

  let overdue = overdueCount(topic)

  return (

    <Menu.Item

      active={active}
      onClick={() => moveToNewTopic()} // dispatch(clearEvents())   }
      style={{cursor: "pointer", fontFamily: "TitleTextFont", fontSize: 13}}

    >

      {(topic.auto_spaced_repetition && overdue > 0) &&
        (<Label style={{ borderRadius: 12, backgroundImage: "linear-gradient( 135deg, rgb(239, 65, 54) 0%, rgb(247, 148, 29) 100% )" }}>{overdue}</Label>)
      }
    { topic.name}

    </ Menu.Item>

  );
}
