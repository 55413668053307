import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Segment, Menu, Checkbox, Item, Button, Select, Grid } from 'semantic-ui-react';
import ModalWrapper from '../../app/common/modals/ModalWrapper';
import { closeModal, openModal } from '../../app/common/modals/modalReducer';
import { setAutoflashcardsEnabled, setRandomOrder, setNumberOfNotes, setReviewType, fetchReviewingNotes } from './reviewActions';
import { useEffect } from 'react';
import { noteBodyWithUnderlineSpans, noteBodyJSX } from '../../app/common/util/util';
import NoteImageAttachment from '../notes/NoteImageAttachment';
import { logEvent } from '../../app/common/logging/logging';
import RichNote from '../notes/RichNote';

export default function FlashcardBack({ note, displayFront, autoflashcardsEnabled, isHowTo }) {
    const dispatch = useDispatch();

    let showAutoflashcardsButton = !note.question && !note.hasUnderlines && !autoflashcardsEnabled

    const { isSubscriber } = useSelector((state) => state.auth);

    async function autoFlashcardsTapped() {
        if (isSubscriber) {
            dispatch(setAutoflashcardsEnabled(true))
            return;
        }

        dispatch(openModal({ modalType: 'SubscribeWall', modalProps: { subWall: true, subTitle: "AutoQuiz requires Notefuel Premium." } }))
        logEvent("Sub Wall Hit Backside Auto")
        
    }

    return (
        <>

            {showAutoflashcardsButton ?
                <div>

                    <div
                    className="titleTextFont"
                        style={{
                            fontSize: 22,
                            textAlign: "center",
                        }}>Not a flashcard yet.
                </div>
                    <div
                        style={{
                            lineHeight: 1.5,
                            padding: 4,
                            fontSize: 15,
                            textAlign: "center",
                        }}>Enable AutoQuiz or edit this note to review it "actively" and improve your recall.
                </div>

                    <Grid columns={16} centered style={{}}>
                        <Grid.Row centered style={{ height: 90 }}>
                            <Grid.Column width={12} style={{ maxWidth: 300 }}>
                                <Button
                                    className='submitButton'
                                    style={{
                                        textAlign: "center",
                                        color: "white",
                                        height: 44,
                                        borderRadius: 22,
                                        width: "100%",
                                        padding: 0,
                                        marginTop: 20,
                                    }}
                                    size='large'
                                    content='Enable AutoQuiz'
                                    onClick={autoFlashcardsTapped}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>



                :

                <>

                    <div style={{ maxHeight: "100%", overflowY: "auto" }}  >
                        <RichNote note={note} context="flashcardBack" autoflashcardsEnabled={autoflashcardsEnabled} ></RichNote>
                        {/* {noteBodyJSX(note, note.question ? "" : "fitbb", false, isHowTo)} */}
                    </div>


                    { note.image_attachment_full_path && (
                        <div style={{ marginTop: 20}}>
                            <NoteImageAttachment note={note} ></NoteImageAttachment>


                        </div>
                    )}
                </>}
        </>
    )
}

function isEmpty(str) {
    return (!str || 0 === str.length);
}