import React, { useState } from "react";

import { Button, Confirm, Container, Dropdown, Grid, Popup, Image, Loader } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import ModalWrapper from "../../app/common/modals/ModalWrapper";
import { Link } from "react-router-dom";
import { deleteNoteInFirestore, updateExcludeInFirestore, addFolderToTopic, removeFolderFromTopic, updateTopicAttributes } from "../../app/firestore/firestoreService";
import { toast } from "react-toastify";
import { closeModal, openModal } from "../../app/common/modals/modalReducer";
import { logEvent } from "../../app/common/logging/logging";
import { useEffect } from "react";
import { format } from "date-fns/esm";
import { loadStripe } from '@stripe/stripe-js';
import UpdatePaymentMethodForm from "./UpdatePaymentMethodForm";
import { Elements } from "@stripe/react-stripe-js";

export default function NewSubscriberModal() {

    const dispatch = useDispatch();

    async function doneTapped() {

        dispatch(closeModal());
        logEvent("New Subscriber Done Tapped")
    }

    return (
        <ModalWrapper
            size="tiny"

            className='nopadding'
            styles={{}}
            maxWidth={410}

        >

<div style={{padding: 16}}>

<div style={{  display: "flex", flexDirection:"column", justifyContent: "center", alignItems: "center" }}>
                    <div style={{}}>

                        <Image
                            src='/assets/logo.png'
                            style={{ height: "auto", width: 50 }}
                        />

                    </div>
                    <div className="titleTextFont" style={{paddingTop: 16, fontSize: 18}}>
                        Welcome to Notefuel Premium!
                    </div>
                    <div className="normalTextFont" style={{paddingTop: 16, fontSize: 16, color: "#3F3F3F", lineHeight: "1.4285em",
                fontWeight: "400", textAlign: "center"}}>
                        Smart move. You've successfully subscribed, and can now use all of Notefuel's features without any limits!
                    </div>                    
                </div>
<div className="normalTextFont" style={{
                    paddingTop: 16,  fontSize: 16, color: "#3F3F3F", lineHeight: "1.4285em",
                    fontWeight: "400", display: "flex", justifyContent: "center"
                }}>
                    <Button
                        onClick={doneTapped}
                        style={{ height: 50, borderRadius: 25, width: 200, fontSize: 16 }}
                        className='reviewCompletePrimaryButton normalTextFont'
                                            >
                        <div>Got it!</div>

                    </Button>
                </div> 
</div>

        </ModalWrapper>
    );
}
