import React from 'react';
import ModalWrapper from '../../app/common/modals/ModalWrapper';

import { useDispatch, useSelector } from 'react-redux';
import { Grid, Button, Image } from 'semantic-ui-react';
import CheckoutForm from './CheckoutForm';
import StripeCheckout from './StripeCheckout';
import BillingPortalButton from './BillingPortalButton';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Link } from 'react-router-dom';
import { fetchSubscriberStatusFromRevenueCat } from '../../revenueCat/revenueCatService';
import { closeModal, openModal } from '../../app/common/modals/modalReducer';
import { toast } from 'react-toastify';
import { logEvent } from '../../app/common/logging/logging';
import { useEffect } from 'react';

export default function SubscribeWall({  notClosable, multiDeviceSubWall, handleSignOut, initialSubscribeView }) {

  const { currentUser } = useSelector((state) => state.auth);
  const { currentUserProfile } = useSelector((state) => state.profile);
  const { modalProps } = useSelector((state) => state.modals);

  const subTitle = modalProps ? modalProps.subTitle : null;
  const showFreeTrial = currentUserProfile.already_used_stripe_trial != true;
  const showCheckoutForm = currentUserProfile.has_unended_stripe_subscriptions != true;


  const dispatch = useDispatch();

  async function subscribeSuccessful() {
    fetchSubscriberStatusFromRevenueCat(currentUser.uid, dispatch);
    dispatch(closeModal());

    dispatch(openModal({ modalType: 'NewSubscriberModal' }));

  }

  async function subscribeCanceled() {

  }

  async function closeTapped() {
    dispatch(closeModal());

  }

  // If you only want to run the function given to useEffect after the initial render, you can give it an empty array as second argument.
  useEffect(() => {
    logEvent("Subscribe View Loaded");

    if (initialSubscribeView) {
      logEvent("Initial Subscribe View Loaded");

    }
  }, []);


  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);


  return (
    <ModalWrapper
      notClosable={notClosable}
      className='subscribeModalWrapper'
      maxWidth={410}
      styles={{
        backgroundImage:
          "linear-gradient(135deg, rgb(239, 65, 54) 0%, rgb(247, 148, 29) 100% !important )",
      }}
    >
      <Grid
        verticalAlign='middle'
        columns={16}
        centered
      //   style={{ backgroundColor: "#FFF" }}
      //   className='customCard'
      >

        { !notClosable &&
        <Button onClick={closeTapped} style={{ color: "#FFF", zIndex: 1000, left: 5, top: 6, position: "absolute", height: 36, width: 36, padding: 0, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", backgroundColor: "rgba(0,0,0,.0)" }}>

          {initialSubscribeView ?
            <div className="normalTextFont" style={{ paddingLeft: 8, paddingTop: 0, fontSize: 16 }}>
              Skip
          </div>

            :

            <img src='/assets/close_x_white.png' alt='logo' style={{ height: 16, width: 16 }} />

          }

          
        </Button>
        }

        <Grid.Row centered style={{ paddingTop: 16 }}>
          <Grid.Column
            width={8}
            style={{
              fontSize: 24,
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Image
              src='/assets/illustration1.png'
              style={{ height: "auto", width: 200 }}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row centered>
          <Grid.Column
            width={16}
            style={{
              fontSize: 24,
              textAlign: "center",
              lineHeight: 1.2,
              maxWidth: 340
            }}
            className="titleTextFont"
          >
            {(showFreeTrial && !subTitle)
              ?
              <span>Try Premium for a week, cancel anytime.</span> : <span>Unlock Notefuel Premium</span>}

          </Grid.Column>
        </Grid.Row>

        {subTitle &&
          <Grid.Row centered style={{
            paddingTop: 0
          }}>
            <Grid.Column
              width={16}
              style={{
                fontSize: 16,
                textAlign: "center",
                lineHeight: 1.2

              }}
              className="italicTextFont"

            >
              {subTitle}
            </Grid.Column>
          </Grid.Row>
        }


        <Grid.Row centered style={{ paddingTop: 20 }}>
          <Grid.Column
            width={16}
            style={{ fontSize: 15, textAlign: "left" }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ display: "inline-block" }}>
                <div style={{ paddingBottom: 4, display: "inline-block", float: "left", clear: "left" }}>
                  {" "}
                  <Image
                    src='/assets/bullet.png'
                    style={{
                      height: "auto",
                      width: 18,
                      paddingBottom: 3,
                      marginRight: 8,

                      display: "inline",
                    }}
                  />{" "}
                AutoQuiz™
              </div>
                <div style={{ paddingBottom: 4, display: "inline-block", float: "left", clear: "left" }}>
                  {" "}
                  <Image
                    src='/assets/bullet.png'
                    style={{
                      height: "auto",
                      width: 18,
                      paddingBottom: 3,
                      marginRight: 8,

                      display: "inline",
                    }}
                  />{" "}
                Spaced repetition
              </div>
                <div style={{ paddingBottom: 4, display: "inline-block", float: "left", clear: "left" }}>
                  {" "}
                  <Image
                    src='/assets/bullet.png'
                    style={{
                      height: "auto",
                      width: 18,
                      paddingBottom: 3,
                      marginRight: 8,

                      display: "inline",
                    }}
                  />{" "}
                Sync across devices
              </div>
                <div style={{ paddingBottom: 4, display: "inline-block", float: "left", clear: "left" }}>
                  {" "}
                  <Image
                    src='/assets/bullet.png'
                    style={{
                      height: "auto",
                      width: 18,
                      paddingBottom: 3,
                      marginRight: 8,

                      display: "inline",
                    }}
                  />{" "}
                Unlimited notes & text scans
              </div>
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>

        {/* <Grid.Row centered style={{ paddingTop: 0 }}>
            <Grid.Column
              width={8}
              style={{ fontSize: 16, textAlign: "center" }}
            >
              <Button
                onClick={subscribe}
                style={{
                  color: "#3F3F3F",
                  height: 40,
                  fontSize: 14,
                }}
                className='reviewCompleteSecondaryButton'
              >
                Terms
              </Button>
            </Grid.Column>
          </Grid.Row> */}

        <Grid.Row centered style={{ paddingTop: 20, paddingBottom: 8 }}>
          <Grid.Column
            width={16}
            style={{ fontSize: 16, textAlign: "center" }}
          >
            <Elements stripe={stripePromise}>
              {showCheckoutForm ?
                <CheckoutForm subscribeSuccessful={subscribeSuccessful} showFreeTrial={showFreeTrial} />
                :
                <BillingPortalButton />
              }
              {/* <StripeCheckout></StripeCheckout> */}
            </Elements>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row centered style={{ paddingTop: 0 }}>
          <Grid.Column
            width={16}
            style={{ fontSize: 14, color: "#FFF", textAlign: "center" }}
          >
            {showFreeTrial ? 'Only $3.50 / week after trial · billed monthly' : 'Only $3.50 / week · billed monthly'}

          </Grid.Column>
        </Grid.Row>

        {multiDeviceSubWall &&
          <Grid.Row centered style={{ paddingTop: 0 }}>
            <Grid.Column
              width={16}
              style={{ fontSize: 13, color: "#FFF", textAlign: "center" }}
            >
                <div className="normalTextFont" style={{
                    paddingTop: 32, fontSize: 15, color: "#FFF", lineHeight: "1.4285em",
                    fontWeight: "400", textAlign: "center"
                }}>
                    Not ready for Premium? <a onClick={handleSignOut} className="titleTextFont" style={{ color: "rgba(256,256,256,0.6)", cursor: "pointer" }}> Sign out</a>
                </div>
            </Grid.Column>
          </Grid.Row>
        }

        <Grid.Row centered style={{ paddingTop: 0, marginTop: 20 }}>
          <Grid.Column
            width={16}
            style={{ fontSize: 11, color: "#FFF", textAlign: "center", opacity: 0.7 }}
          >
            Payments are processed securely by <a  onClick={() => { logEvent("Subscribe Stripe Tapped")}}  style={{ fontSize: 11, color: "#FFF", textAlign: "center", opacity: 0.6 }} target="_blank" href="https://www.stripe.com" >Stripe</ a > and are subject to our {' '}
            <Link onClick={() => { logEvent("Subscribe Terms Tapped")}} style={{ fontSize: 11, color: "#FFF", textAlign: "center", opacity: 0.6 }} to={`/terms`} target="_blank">Terms of Service</ Link >.
            </Grid.Column>
        </Grid.Row>


      </Grid>
    </ModalWrapper>
  );
}