import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Segment, Menu, Checkbox, Item, Button, Select, Grid, Popup, Image, Confirm} from 'semantic-ui-react';
import ModalWrapper from '../../app/common/modals/ModalWrapper';
import { closeModal, openModal } from '../../app/common/modals/modalReducer';
import { setAutoflashcardsEnabled, setRandomOrder, setNumberOfNotes, setReviewType, setStage, setSelectedNotes } from './reviewActions';
import { getNextReviewDateForSort, shuffleArray, sortNotesForTopic } from '../../app/common/util/util';
import { useEffect } from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { updateAutoSpacedRepetition } from '../../app/firestore/firestoreService';
import { logEvent } from '../../app/common/logging/logging';

export default function ReviewConfigureView() {
    const dispatch = useDispatch();

    const { selectedTopic } = useSelector((state) => state.topic);
    const { reviewType, numberOfNotes, notes, suggestedNotes, randomOrder, autoflashcardsEnabled } = useSelector((state) => state.review);
    const { isSubscriber } = useSelector((state) => state.auth);

    const [confirmOpen, setConfirmOpen] = useState(false);

    const totalNumberOfNotes = reviewType == "custom" ? notes.length : suggestedNotes.length

    var noteCountOptions = []
    for (var i = 1; i <= totalNumberOfNotes; i++) {
        noteCountOptions.push({ key: i, value: i, text: String(i) })
    }
    
    function compareByCreatedAt(a, b) {
        return b.created_at - a.created_at;
      }
      
    function compareByNextReviewDate(a, b) {
        return getNextReviewDateForSort(a.review_history) - getNextReviewDateForSort(b.review_history) ;
    }


    async function suggestedReviewTypeSelected() {


      logEvent("Review Type Tapped", {review_type: "suggested" })
        if (isSubscriber) {
          if (selectedTopic.auto_spaced_repetition == true) {
            dispatch(setReviewType("suggested"))
            return;
          } else {
            setConfirmOpen(true);
            return;
          }

        }

        dispatch(openModal({ modalType: 'SubscribeWall', modalProps: { subWall: true, subTitle: "Suggested reviews are part of Notefuel Premium's spaced repetition feature." } }))
        logEvent("Sub Wall Hit Suggested Review")


    }
    async function customReviewTypeSelected() {
        dispatch(setReviewType("custom"))
        logEvent("Review Type Tapped", {review_type: "custom" })

    }

    async function autoFlashcardsChanged(event, data) {

      logEvent("Auto Flashcards Switch Tapped", {auto_flashcards_on: data.checked })


        if (isSubscriber || !data.checked) {
            dispatch(setAutoflashcardsEnabled(data.checked))
            return;
        }

        dispatch(openModal({ modalType: 'SubscribeWall', modalProps: { subWall: true, subTitle: "AutoQuiz requires Notefuel Premium." } }))
        logEvent("Sub Wall Hit Quiz Auto")

    }

    async function handleTurnedOnAutoSpacedRepetition() {
      setConfirmOpen(false);
      try {
        dispatch(setReviewType("suggested"))

        await updateAutoSpacedRepetition(selectedTopic, true);

      } catch (error) {
        toast.error(error.message);
      }
    }    

    async function beginReview() {

        // Do the initial sort to select the ones that you're gonna review, which is either by most need of review, or simply chronological
        if (reviewType == "suggested" ) {
            var initiallySortedNotes = suggestedNotes;
        } else {
            if (randomOrder) {
              var initiallySortedNotes = shuffleArray(notes)

            } else if (selectedTopic.how_to) { //&& RCValues.sharedInstance.bool(forKey: .reverseUserGuideOrder) {
                var initiallySortedNotes =  notes.sort((a, b) =>  (sortNotesForTopic(a, b, selectedTopic)))

            } else {
                var initiallySortedNotes =  notes.sort((a, b) =>  (sortNotesForTopic(a, b, selectedTopic))).reverse();

            }
            
        }
        
        // Then, cut down to the only N notes
        var filteredNotes = initiallySortedNotes.slice(0, numberOfNotes)

        if (reviewType == "suggested" || randomOrder) {
            filteredNotes = shuffleArray(filteredNotes)
        }


        // Shuffle the multiple choice for each note]

        filteredNotes.forEach((note) => {
          if (false && note.oa_choices && note.oa_choices.length > 0 && note.oa_cloze_deletion)
          note.oa_choices_randomized = shuffleArray(note.oa_choices.concat([note.oa_cloze_deletion]))
        })
    

        dispatch(setSelectedNotes(filteredNotes));
        dispatch(setStage("reviewing"));

        logEvent("Begin review tapped")

     }

    return (
      <Grid columns={16} centered style={{paddingLeft: 16, paddingRight: 16}}>
        <Grid.Row centered>
          <Grid.Column width={16} className="constrainedColumn">
            <Grid
              verticalAlign='middle'
              columns={16}
              centered
              style={{ backgroundColor: "#FFF" }}
              className='customCard'
            >
              <Grid.Row centered className='configureReviewRow'>
                <Grid.Column width={6}>
                  Notes to review
                  <Popup
                    position='right'
                    content={
                      'Notefuel knows exactly when you should review each note for optimally efficient information retention. If you select "Suggested", Notefuel will limit the review to only the notes you\'d benefit from reviewing right now.'
                    }
                    // key={user.name}
                    // header={user.name}
                    trigger={
                      <Image
                        inline
                        style={{ marginLeft: 8, height: 16, width: 16 }}
                        src='/assets/info.png'
                        className="reviewTypePopup"
                      />
                    }
                  />
                </Grid.Column>
                <Grid.Column width={10}>
                  <Menu
                    compact
                    color='#FFF'
                    style={{
                      border: 0,
                      color: "#3F3F3F",
                      backgroundColor: "#E9E9EB",
                    }}
                    className='reviewTypeMenu right floated'
                  >
                    <Menu.Item
                      active={reviewType == "custom"}
                      onClick={customReviewTypeSelected}
                      style={{
                        padding: 12,
                      }}
                    >
                    
                      Any
                    </Menu.Item>

                    <Menu.Item
                      active={reviewType == "suggested"}
                      onClick={suggestedReviewTypeSelected}
                      style={{
                        padding: 12,
                      }}
                    >
                      Suggested
                    </Menu.Item>

                  </Menu>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row centered className='configureReviewRow'>
                <Grid.Column width={12}>
                  Enable<br className="showOnlyOnMobile"></br> AutoQuiz
                  <Popup
                    position='right'
                    content={
                      'The AutoQuiz feature uses AI to turn your notes into fill-in-the-blank flashcards automatically. This lets you review your notes "actively" without doing any extra work!'
                    }
                    // key={user.name}
                    // header={user.name}
                    trigger={
                      <Image
                        inline
                        style={{ marginLeft: 8, height: 16, width: 16 }}
                        src='/assets/info.png'
                      />
                    }
                  />
                </Grid.Column>
                <Grid.Column width={4}>
                  <Checkbox
                    className='right floated'
                    toggle
                    onChange={autoFlashcardsChanged}
                    checked={autoflashcardsEnabled}
                  ></Checkbox>
                </Grid.Column>
              </Grid.Row>

              {reviewType == "custom" && (
                <Grid.Row centered className='configureReviewRow'>
                  <Grid.Column width={12}>Randomize order</Grid.Column>
                  <Grid.Column width={4}>
                    <Checkbox
                      className='right floated'
                      toggle
                      onChange={(event, data) => {
                        logEvent("Order Switch Tapped", {order: data.checked })
                        dispatch(setRandomOrder(data.checked));
                      }}
                    ></Checkbox>
                  </Grid.Column>
                </Grid.Row>
              )}

              <Grid.Row centered className='configureReviewRow'>
                <Grid.Column width={5} style={{paddingRight: 2}}>
                  #<span className="hideOnMobile"> of notes</span> to review
                </Grid.Column>
                <Grid.Column width={11} style={{paddingLeft: 2}}>
                  {totalNumberOfNotes > 0 ? (
                    <div
                      className='right floated'
                      style={{ fontSize: 14, color: "#929292" }}
                    >
                      <Select
                        text={
                          String(numberOfNotes) ??
                          (noteCountOptions.length > 0
                            ? noteCountOptions[noteCountOptions.length - 1].text
                            : "")
                        }
                        options={noteCountOptions}
                        style={{
                          minWidth: 64,
                          width: 64,
                          fontSize: 14,
                          textAlign: "right",
                          border: 0,
                        }}
                        className='noteSelect'
                        onChange={(event, data) => {
                          dispatch(setNumberOfNotes(data.value));
                        }}
                      />{" "}
                      of{" "}
                      <span style={{ fontSize: 14, color: "#EF4136" }}>
                        {" "}
                        {reviewType == "suggested"
                          ? suggestedNotes.length
                          : notes.length}{" "}
                      </span>
                      <span>
                        {" "}
                        {reviewType == "suggested" ? " suggested" : " total"}
                      </span>
                    </div>
                  ) : (
                    <div
                      className='right floated'
                      style={{ fontSize: 14, color: "#929292" }}
                    >
                      <span style={{ fontSize: 14, color: "#EF4136" }}>
                        {" "}
                        0{" "}
                      </span>
                      <span style={{ fontSize: 14, color: "#929292" }}>
                        {" "}
                        {reviewType == "suggested"
                          ? " suggested"
                          : "  notes"}
                      </span>
                    </div>
                  )}
                </Grid.Column>
              </Grid.Row>

              <Grid.Row centered style={{ height: 90 }}>
                <Grid.Column width={12} style={{maxWidth: 200}}>
                  <Button
                    disabled={!numberOfNotes || numberOfNotes <= 0}
                    className='submitButton'
                    style={{
                      textAlign: "center",
                      color: "white",
                      height: 44,
                      borderRadius: 22,
                      width: "100%",
                      padding: 0,
                      margin: 0,
                    }}
                    size='large'
                    content='Begin review'
                    onClick={beginReview}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>

        <Confirm
        content={
          "To conduct a suggested review, you need to enable spaced repetition for this topic. This tells Notefuel to notify you when you're due to review a topic's notes."
        }
        header="Turn on spaced repetition?"
        open={confirmOpen}
        onCancel={() => setConfirmOpen(false)}
        onConfirm={handleTurnedOnAutoSpacedRepetition}
        size='mini'
      />

      </Grid>
    );
}