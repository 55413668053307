import { setSubscriber } from "../features/auth/authActions";
import axios from 'axios'
import { logError } from "../app/common/logging/logging";
export function fetchSubscriberStatusFromRevenueCat(uid, dispatch) {

    const options = {
      method: 'GET',
      url: 'https://api.revenuecat.com/v1/subscribers/' + uid,
      headers: {
        'content-type': 'application/json',
        'x-platform': 'ios',
        authorization: 'Bearer nHZBLrgBnoezywmcDwCqExTKfmBlKmJC'
      }
    };

    axios(options)
        .then(function (response) {
            const data = response.data

           dispatch(setSubscriber(data.subscriber));

        })
        .catch(function (error) {

            logError(error);
        })



}





