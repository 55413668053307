import React, { useCallback } from 'react';
import { Segment, Popup, Button, Grid, Confirm } from 'semantic-ui-react';
import { format } from 'date-fns';
import { noteBodyJSX, isEmpty } from '../../app/common/util/util';
import { useDispatch, useSelector } from 'react-redux';
import { openModal, closeModal } from '../../app/common/modals/modalReducer';
import { useEffect } from 'react';
import { useState } from 'react';
import firebase from '../../app/config/firebase';
import NoteImageAttachment from './NoteImageAttachment';
import { deleteNoteInFirestore, updateExcludeInFirestore, incrementNoteCount } from '../../app/firestore/firestoreService';
import { toast } from 'react-toastify';
import { logEvent, consoleLog } from '../../app/common/logging/logging';
import { Slate, withReact } from 'slate-react';
import { Editable } from 'slate-react'
import { CustomElement, CustomLeaf, withClozes } from '../editor/NoteEditor';
import { useMemo } from 'react';
import { withHistory } from 'slate-history';
import { createEditor } from 'slate'

export default function RichNote({ note, context, autoflashcardsEnabled }) {

    var hideUnderlines = false;
    var hideAutoUnderlines = false;

    var showUnderlinesAsAnswer = false;
    var showAutoUnderlinesAsAnswer = false;

    if (context === "flashcardFront") {

        if (note.hasUnderlines) {
            hideUnderlines = true
        } else if (autoflashcardsEnabled && note.hasAutoUnderlines) {
            hideAutoUnderlines = true
        }
    }

    // If we have a question, then just use the default styling
    // for the card back
    if (context === "flashcardBack" && isEmpty(note.question)) {

        if (note.hasUnderlines) {
            showUnderlinesAsAnswer = true
        } else if (autoflashcardsEnabled && note.hasAutoUnderlines) {
            showAutoUnderlinesAsAnswer = true
        }
    }

    const renderElement = useCallback(props => <CustomElement {...props} context={context} />, [])
    const renderLeaf = useCallback(props => <CustomLeaf {...props} context={context} hideUnderlines={hideUnderlines} hideAutoUnderlines={hideAutoUnderlines}
        showUnderlinesAsAnswer={showUnderlinesAsAnswer}
        showAutoUnderlinesAsAnswer={showAutoUnderlinesAsAnswer}

    />, [])



    const editor = useMemo(
        () => withHistory(withReact(createEditor())),
        []
    )
    return (
        <div  >

            <Slate
                editor={editor}
                value={note.rich_body}
            >

                <Editable
                    readOnly
                    style={{ fontSize: ((context == "flashcardBack" || context == "flashcardFront") ? 18 : 14) }}
                    renderElement={renderElement}
                    renderLeaf={renderLeaf}
                ></Editable>
            </Slate>

        </div>
    );
}

