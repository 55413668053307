import React from 'react';

import LoadingComponent from '../../app/layout/LoadingComponent';
import { useHistory } from 'react-router-dom';
import { completeSignInPasswordlessIfAppropriate } from '../../app/firestore/firebaseService';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { logError } from '../../app/common/logging/logging';

export default function SigningInLoader({ }) {

    const history = useHistory();
    const dispatch = useDispatch();

    async function signInAndRedirectHome() {
      try {
        await  completeSignInPasswordlessIfAppropriate(dispatch, history);
      } catch (error) {

        logError(error)
        history.push("/")
      }
    }

    useEffect(() => {
        
        signInAndRedirectHome()
    
        return () => {
    
        }
      }, []);
    

  return (
    <LoadingComponent content='Signing in...' /> 
  );
}

