import React, { useState } from "react";

import { Button, Confirm, Container, Dropdown, Grid, Popup, Image, Loader } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import ModalWrapper from "../../app/common/modals/ModalWrapper";
import { Link } from "react-router-dom";
import { deleteNoteInFirestore, updateExcludeInFirestore, addFolderToTopic, removeFolderFromTopic, updateTopicAttributes } from "../../app/firestore/firestoreService";
import { toast } from "react-toastify";
import { closeModal, openModal } from "../../app/common/modals/modalReducer";
import { logEvent } from "../../app/common/logging/logging";
import { useEffect } from "react";
import { format } from "date-fns/esm";
import { loadStripe } from '@stripe/stripe-js';
import UpdatePaymentMethodForm from "./UpdatePaymentMethodForm";
import { Elements } from "@stripe/react-stripe-js";
import firebase from '../../app/config/firebase'

export default function StripeSubscriptionModal() {

    
    const dispatch = useDispatch();

    const { selectedTopic } = useSelector((state) => state.topic);
    const { isSubscriber } = useSelector((state) => state.auth);
    const { currentUserProfile } = useSelector((state) => state.profile);


    const [loading, setLoading] = useState(true);

    const [subscriptions, setSubscriptions] = useState(null);
    const [paymentMethods, setPaymentMethods] = useState(null);
    const [showUpdatePaymentMethodForm, setShowUpdatePaymentMethodForm] = useState(false);

    const [confirmOpen, setConfirmOpen] = useState(false);

    async function getUserStripeSubscriptions() {
        setLoading(true);
        const functionRef = firebase.app().functions("us-central1").httpsCallable('stripeGetSubscriptions');
        const { data } = await functionRef({});
        setPaymentMethods(data.paymentMethods);
        setSubscriptions(data.subscriptions);

        setLoading(false);

    }

    async function setCancelAtPeriodEnd(subscriptionId, cancelAtPeriodEnd) {


        setLoading(true);
        setConfirmOpen(false)

        try {
            const functionRef = firebase.app().functions("us-central1").httpsCallable('stripeSetCancelAtPeriodEnd');
            const { data } = await functionRef({ subscriptionId, cancelAtPeriodEnd: cancelAtPeriodEnd });

            setPaymentMethods(data.paymentMethods);
            setSubscriptions(data.subscriptions);

            if (cancelAtPeriodEnd) {
                toast.info("Your Notefuel Premium subscription has been canceled and will not renew.")

            } else {
                toast.info("Your Notefuel Premium subscription has been updated, and will now renew at the end of the current period.")

            }

        } catch (error) {

            if (cancelAtPeriodEnd) {
                toast.error("Error attempting to cancel your subscription. Please try again momentarily, or contact us for assistance.")

            } else {
                toast.error("Error attempting to update your subscription. Please try again momentarily, or contact us for assistance.")
            }
        }
        setLoading(false);

    }

    async function cancelStripeSubscription(subscriptionId) {


        setLoading(true);
        setConfirmOpen(false)

        try {
            const functionRef = firebase.app().functions("us-central1").httpsCallable('stripeCancelSubscription');
            const { data } = await functionRef({ subscriptionId });
            toast.info("Your Notefuel Premium subscription has been canceled - we're sorry to see you go!")

        } catch (error) {
            toast.error("Error attempting to cancel your subscription. Please try again momentarily, or contact us for immediate assistance.")
        }
        setLoading(false);

    }


    function getCardDataForSubscription(subscription) {

        const foundPaymentMethod = paymentMethods.find(paymentMethod => (paymentMethod.id === subscription.default_payment_method));

        if (foundPaymentMethod && foundPaymentMethod.card) {
            return foundPaymentMethod.card.brand.toUpperCase() + " ending in " + foundPaymentMethod.card.last4
        } else {
            return null;
        }


    }


    function updateSuccessful(data) {
        setPaymentMethods(data.paymentMethods);
        setSubscriptions(data.subscriptions);

        toast.info("Your payment method has been updated.")

        setShowUpdatePaymentMethodForm(false);
    }

    useEffect(() => {
        getUserStripeSubscriptions();
    }, []);

    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);


    return (
        <ModalWrapper
            size="tiny"
            header={
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <div style={{}}>

                        <Image
                            src='/assets/logo.png'
                            style={{ height: "auto", width: 50 }}
                        />

                    </div>
                    <div className="titleTextFont" style={{ paddingTop: 16, fontSize: 18 }}>
                        <span className="normalTextFont">Your Plan: </span>Notefuel Premium
                    </div>
                    <div className="normalTextFont" style={{
                        paddingTop: 16, fontSize: 15, color: "#3F3F3F", lineHeight: "1.4285em",
                        fontWeight: "400", textAlign: "center"
                    }}>
                        Unlocks all of Notefuel's features, and gives you unlimited notes and text scans.
                    </div>
                </div>
            }
            className='nopadding'
            styles={{}}
            maxWidth={410}

        >

            <div>
                {loading &&

                    (<div style={{ marginTop: 16, backgroundColor: "#FFF", display: "flex", flexDirection: "row", justifyContent: "center", borderRadius: 10 }}>
                        <div style={{ paddingBottom: 16 }}>
                            <Loader active inline size="large" style={{ left: 0 }} className="stripeModalLoader" />

                        </div>
                    </div>)
                }

                {(subscriptions && subscriptions.length == 0) &&
                    <div style={{
                        display: "flex", flexDirection: "column", fontSize: 15,
                        padding: 16, textAlign: "center"
                    }}>
                        No subscriptions found.
    </div>


                }

                {(!loading && subscriptions) && subscriptions.filter((e) => e.customer === currentUserProfile.stripeId).map((subscription) => (

                    <div
                        style={{
                            display: "flex", flexDirection: "column", fontSize: 15,
                            padding: 16, borderBottom: "1px solid rgba(34,36,38,.15)"
                        }}>
                        <div className="titleTextFont" style={{ paddingTop: 0, paddingBottom: 16, fontSize: 17 }}>
                            Payment
</div>

                        <div style={{ paddingTop: 0, paddingBottom: 16 }}>


                            Your payment method is a <span className="titleTextFont">{getCardDataForSubscription(subscription)}</span>.

                    </div>


                        <div style={{}}>

                            <a style={{ color: "#EF4136", cursor: "pointer" }} onClick={() => setShowUpdatePaymentMethodForm(!showUpdatePaymentMethodForm)}>Update payment method</a>
                        </div>

                        {showUpdatePaymentMethodForm &&

                            <div style={{ marginTop: 16, padding: 8, border: "1px solid rgba(34,36,38,.15)" }}>

                                <Elements stripe={stripePromise}>
                                    <UpdatePaymentMethodForm updateSuccessful={updateSuccessful} subscriptionId={subscription.id}></UpdatePaymentMethodForm>
                                </Elements>
                            </div>
                        }



                        <div style={{ paddingTop: 16 }}>
                            {subscription.cancel_at_period_end === true ?
                                <>Your subscription has been canceled. You will continue to have access to Notefuel Premium until  <span className="titleTextFont">{format(new Date(subscription.cancel_at * 1000), 'MM/dd/yy')}</span>. </>
                                :
                                <>
                                    {subscription.status == "trialing" &&
                                        <>Your are on a free trial until <span className="titleTextFont">{format(new Date(subscription.trial_end * 1000), 'MM/dd/yy')}</span>. </>
                                    }
                        Your next bill is for <span className="titleTextFont">${JSON.stringify(subscription.plan.amount_decimal / 100)}</span> on <span className="titleTextFont">{format(new Date(subscription.current_period_end * 1000), 'MM/dd/yy')}</span>.
                                
                                {/* <div>
                                    {JSON.stringify(subscription.plan.amount_decimal / 100)}
                                </div> */}

</>


                            }
                        </div>
                        <div style={{ paddingTop: 16 }}>


                            {subscription.cancel_at_period_end === true ?
                                <a style={{ color: "#EF4136", cursor: "pointer" }} onClick={() => setCancelAtPeriodEnd(subscription.id, false)}>Restart subscription</a>
                                :
                                <a style={{ color: "#EF4136", cursor: "pointer" }} onClick={() => setConfirmOpen(true)}>Cancel subscription</a>


                            }


                            <Confirm
                                header='Cancel subscription'

                                content={
                                    "Are you sure you want to cancel your Notefuel Premium subscription?"
                                }
                                confirmButton={"Cancel subscription"}
                                open={confirmOpen}
                                onCancel={() => setConfirmOpen(false)}
                                onConfirm={() => setCancelAtPeriodEnd(subscription.id, true)}
                            //onConfirm={() => cancelStripeSubscription(subscription.id)} // Fully cancels for testing
                            />

                        </div>


                        {/* <div style={{}}>5

                        Status: {subscription.status}
                    </div>

                    <div style={{}}>

                        Cancel at period end: {subscription.cancel_at_period_end}ddd
                    </div> */}

                    </div>

                ))}
            </div>

        </ModalWrapper>
    );
}
