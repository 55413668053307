import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Segment, Menu, Checkbox, Item, Button, Select, Grid, Progress, Container } from 'semantic-ui-react';

import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox, Hits } from 'react-instantsearch-dom';
import { setSearchKeys } from './searchActions';
import firebase from '../../app/config/firebase'
import { useEffect } from 'react';
import SearchResultItem from './SearchResultItem';

import { Detector } from "react-detect-offline";



export default function SearchWrapper({ match }) {
    const dispatch = useDispatch();

    const { selectedTopic } = useSelector((state) => state.topic);
    const { reviewType, notes, showCurrentAnswer, stage } = useSelector((state) => state.review);
    const { sidebarVisible } = useSelector(state => state.sidebar);

    const { hits } = useSelector(state => state.search);

    return (

        <div style={{ display: "flex", width: "100%", flexDirection: "column", justifyContent: "flex-end", height: "100%", minHeight: "100%", paddingTop: 0 }}>


            <div style={{ backgroundColor: "", flex: "1 0 auto", paddingTop: 60, paddingBottom: 0 }}>
                <div style={{ backgroundColor: "", maxHeight: "100%", height: "100%", display: "flex", flexDirection: "column", alignItems: "stretch", position: "relative", overflow: "" }}>

                    <div style={{ backgroundColor: "", height: "100%", width: "100%", position: "absolute", top: 0, right: 0, display: "flex", flexDirection: "column", overflow: "auto", paddingLeft: 20, paddingRight: 20 }}>
                        <Container className={sidebarVisible ? "sidebarVisible" : ""} style={{ fontSize: 16, paddingTop: 0, display: "flex", flexDirection: "column" }}>

                            <Detector
                                render={({ online }) => (
                                    online ?
                                        <>
                                            {hits.map((hit) => (
                                                <SearchResultItem hit={hit} />
                                            ))}
                                        </>
                                        :
                                        <div style={{textAlign: "center", paddingTop: 32}}>

                                            <div className="titleTextFont" style={{fontSize: 18}}>
                                               You're offline right now.
                                    </div>
                                            <div>
                                                 Searching your notes requires an internet connection.
                                                               </div>
                                        </div>

                                )} />

                        </Container>
                    </div>

                </div>
            </div>




        </div>



    )
}