
import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import ReviewCarouselItem from './ReviewCarouselItem';

const handleDragStart = (e) => e.preventDefault();


const reviews = [
    {
        userName: "C Coop",
        date: "1/29/2020",
        title: "Game-changing app for serious learners",
        text: "Do you like to remember what you read without having to devise some elaborate, time-consuming system? Me too. It uses spaced repetition to ensure you retain all that knowledge!"
    
    },
    {
        userName: "Askim818",
        date: "4/27/2020",
        title: "Smart way to learn",
        text: "Taking notes takes me sooooo long, this app is a time saver!" + 
        " I can take a picture of my book and just keep what I need to learn and thats it I move on to the next. "
    },
    {
        userName: "marioarroyo",
        date: "10/27/2020",
        title: "Exactly what I've been searching for",
        text: "Notefuel is the first app that gives me the freedom to study longer passages. I have searched all over the App Store for something like this."
    },

    {
        userName: "Cyclebrity",
        date: "5/28/2020",
        title: "Smart way to learn",
        text: "This is way better than taking notes manually with a paper and pencil. "
    + "It uses algorithms to help you learn the content you create faster than manual flashcards."
    },

    // {
    //     userName: "Askim818",
    //     date: "4/27/2020",
    //     text: "I am loving the app,! I can take a picture of my book and just keep what I need to learn and thats it I move on to the next. I had literally been saying this should exist, thanks for making it happen!"
    // },

    // {
    //     userName: "LinaB91",
    //     date: "11/3/2019",
    //     text: "At school we were taught the Cornell note taking system. It’s always been my favorite note taking style because you add notes incrementally and then quiz yourself by covering have the page. Basically your notes can also serve as flashcards. I’ve been looking for a note taking app that lets me use Cornell notes on my iPhone and I found this one, so I now use it for books, school, etc. the scan text feature is also useful. I recommend for note taking if you want to review your notes."
    // }

]

const items = [
    <ReviewCarouselItem />,
    <ReviewCarouselItem />,
    <ReviewCarouselItem />,
    <ReviewCarouselItem />,

];


export default function ReviewCarousel() {

    return (
        <AliceCarousel id="reviewCarousel" mouseTracking items={reviews.map( review =>  <ReviewCarouselItem review={review} />)} disableButtonsControls={true} />
    );

}
