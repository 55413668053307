import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Segment, Menu, Checkbox, Item, Button, Select, Grid } from 'semantic-ui-react';
import ModalWrapper from '../../app/common/modals/ModalWrapper';
import { closeModal } from '../../app/common/modals/modalReducer';
import { setAutoflashcardsEnabled, setRandomOrder, setNumberOfNotes, setReviewType, fetchReviewingNotes } from './reviewActions';
import { useEffect } from 'react';
import { noteBodyWithUnderlineSpans, noteBodyJSX } from '../../app/common/util/util';
import NoteImageAttachment from '../notes/NoteImageAttachment';
import RichNote from '../notes/RichNote';

export default function FlashcardFront({ note, displayFront, autoflashcardsEnabled, isHowTo }) {

    return (

        <div style={{ maxHeight: "100%", overflowY: "auto" }}>
            {note.question ?
                (<div style={{ maxHeight: "100%", overflowY: "auto" }} className="reviewQuestionText">{note.question}</div>) :

                (
                    <>
                        <div style={{ maxHeight: "100%", overflowY: "auto" }}  >
                            {/* {noteBodyJSX(note, autoflashcardsEnabled ? "fitbf" : "", false, isHowTo)} */}
                            <RichNote note={note} context="flashcardFront" autoflashcardsEnabled={ autoflashcardsEnabled }></RichNote>
                        </div>

                        {note.image_attachment_full_path && (
                            <div style={{marginTop: 20 }}>
                                <NoteImageAttachment note={note} ></NoteImageAttachment>


                            </div>
                        )}
                    </>

                )

            }
        </div>





    )
}