import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Segment, Menu, Checkbox, Item, Button, Select, Grid } from 'semantic-ui-react';
import ModalWrapper from '../../app/common/modals/ModalWrapper';
import { closeModal } from '../../app/common/modals/modalReducer';
import { setAutoflashcardsEnabled, setRandomOrder, setNumberOfNotes, setReviewType, fetchReviewingNotes } from './reviewActions';
import { useEffect } from 'react';
import FlashcardFront from './FlashcardFront';
import FlashcardBack from './FlashcardBack';

export default function MultipleChoiceButton({ reviewType, showCurrentAnswer, currentNoteIndex, selectedNotes, autoflashcardsEnabled, isHowTo, choice }) {

    return (
        <div style={{flex: "1 1 50%", margin: "10", padding: 8}}>
                                    <Button className="secondaryButton normalTextFont"
                            style={{  height: 40, width: "100%", borderRadius: 20, fontSize: 14 }}
                            disabled={false } //!isValid || !dirty || isSubmitting}
                            fluid
                            size='large'
                            content={choice}
                        />

        </div>





    )
}