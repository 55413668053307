import React from "react";

import { useDispatch, useSelector } from "react-redux";
import {

  Button,
  Grid
} from "semantic-ui-react";

import {
  flipCard,
  nextCard,
  previousCard,
} from "./reviewActions";
import { useEffect } from "react";
import { useCallback } from "react";
import { addToReviewHistory } from "../../app/firestore/firestoreService";
import FlashcardWrapper from "./FlashcardWrapper";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { logEvent } from "../../app/common/logging/logging";
import MultipleChoiceButtonPanel from "./MultipleChoiceButtonPanel";
import { shuffleArray, shuffleArr } from "../../app/common/util/util";

export default function ReviewView() {
  const dispatch = useDispatch();

  const {
    reviewType,
    showCurrentAnswer,
    currentNoteIndex,
    selectedNotes,
    autoflashcardsEnabled,
    indexOfLastViewedCardForUndo,
  } = useSelector((state) => state.review);
  const { selectedTopic } = useSelector((state) => state.topic);

  async function handleFlip() {

    //console.log("Current note: " + JSON.stringify(selectedNotes[currentNoteIndex]))

    dispatch(flipCard());
  }

  async function handleSwipedCard(noteId, grade, selectedTopic) {
    addToReviewHistory(noteId, grade, selectedTopic);

    dispatch(nextCard(grade > 3));

    logEvent("Card Graded")
  }

  async function undo() {

    if (currentNoteIndex > 0) {
      dispatch(previousCard()); 
    }

  }

  function getClassNamesForAnimation() {
    if (indexOfLastViewedCardForUndo > currentNoteIndex) {
      return "undo";
    }

    if (currentNoteIndex === 0) {
      return "";
    }

    return selectedNotes[currentNoteIndex - 1].recalled_during_review
      ? "recalled"
      : "forgotten";
  }

  const currentModal = useSelector((state) => state.modals);


  const onKeydown = useCallback(
    (e) => {

      // Don't want to block keystrokes if a modal like 
      // the add topic modal is showing.
      if (currentModal) {
        return;
      }

      e.preventDefault();
      if (e.keyCode === 32) {
        handleFlip();
      } else if (e.keyCode === 37) {
        handleSwipedCard(selectedNotes[currentNoteIndex].id, 0, selectedTopic);
      } else if (e.keyCode === 39) {
        handleSwipedCard(selectedNotes[currentNoteIndex].id, 5, selectedTopic);
      } else if (e.keyCode === 8) {
        undo();
      }
    },
    [showCurrentAnswer, currentNoteIndex, selectedNotes, currentModal]
  );

  const prevent = useCallback((e) => {
    e.preventDefault();
  }, []);

  useEffect(() => {
    window.addEventListener("keydown", onKeydown);

    return () => {
      window.removeEventListener("keydown", onKeydown);
    };
  }, [prevent, onKeydown]);

  var rememberedNotes = 0;
  var forgottenNotes = 0;

  for (var i = 0; i < currentNoteIndex; i++) {
    if (selectedNotes[i].recalled_during_review) {
      rememberedNotes = rememberedNotes + 1;
    } else {
      forgottenNotes = forgottenNotes + 1;
    }
  }

  var percentageRecalled = (100 * rememberedNotes) / selectedNotes.length;
  var percentageForgotten = (100 * forgottenNotes) / selectedNotes.length;

  return (
    <>
      <div className='progressBarWrapper'>
        <div
          className='progressBar'
          style={{
            width: percentageForgotten + "%",
            backgroundColor: "#EF4136",
          }}
        >
          {" "}
        </div>

        <div
          className='progressBar'
          style={{
            width: percentageRecalled + "%",
            backgroundColor: "#3CBC5F",
          }}
        >
          {" "}
        </div>
      </div>

      {selectedNotes && selectedNotes[0] && (
        <Grid columns={16} centered style={{}}>
          <Grid.Row centered>
            <Grid.Column
              width={16}
              onClick={handleFlip}
              style={{ maxWidth: 400 }}
              className={getClassNamesForAnimation() + " flashcardWithFixedHeight"}
            >
              <SwitchTransition mode='out-in'>
                <CSSTransition
                  key={currentNoteIndex}
                  addEndListener={(node, done) => {
                    node.addEventListener("transitionend", done, false);
                  }}
                  classNames={"animated"}

                >
                  <FlashcardWrapper
                    key={currentNoteIndex}
                    reviewType={reviewType}
                    showCurrentAnswer={showCurrentAnswer}
                    currentNoteIndex={currentNoteIndex}
                    selectedNotes={selectedNotes}
                    autoflashcardsEnabled={autoflashcardsEnabled}
                    isHowTo={selectedTopic && selectedTopic.how_to == true}
                    ></FlashcardWrapper>
                </CSSTransition>
              </SwitchTransition>
            </Grid.Column>
          </Grid.Row>



          <Grid.Row centered style={{ paddingBottom: 36 , maxWidth: 400}}>

            {( selectedNotes[currentNoteIndex].oa_choices_randomized) ?


              (<MultipleChoiceButtonPanel showCurrentAnswer={showCurrentAnswer}
                currentNoteIndex={currentNoteIndex}
                selectedNotes={selectedNotes}
                autoflashcardsEnabled={autoflashcardsEnabled}
               
                choices={selectedNotes[currentNoteIndex].oa_choices_randomized }
              />)


              :

              <>
                <Grid.Column width={8} style={{ textAlign: "center" }}>
                  <Button
                    onClick={() =>
                      handleSwipedCard(
                        selectedNotes[currentNoteIndex].id,
                        0,
                        selectedTopic
                      )
                    }
                    style={{ borderLeft: 0, backgroundColor: "#fff" }}
                    className='reviewButton'
                  >
                    <img
                      src='/assets/forgotten.png'
                      alt='logo'
                      style={{
                        height: "auto",
                        width: 21,
                        paddingTop: 1,
                        paddingLeft: 1,
                        backgroundColor: "#fff",
                      }}
                    />
                  </Button>
                </Grid.Column>

                <Grid.Column width={8} style={{ textAlign: "center" }}>
                  <Button
                    onClick={() =>
                      handleSwipedCard(
                        selectedNotes[currentNoteIndex].id,
                        5,
                        selectedTopic
                      )
                    }
                    style={{ borderLeft: 0, backgroundColor: "#fff" }}
                    className='reviewButton'
                  >
                    <img
                      src='/assets/recalled.png'
                      alt='logo'
                      style={{
                        height: "auto",
                        width: 25,
                        paddingTop: 1,
                        paddingLeft: 1,
                        backgroundColor: "#fff",
                      }}
                    />
                  </Button>
                </Grid.Column>
              </>

            }

          </Grid.Row>
        </Grid>
      )}
    </>
  );
}
