import firebase from '../../config/firebase'
import { format } from 'date-fns';
import React from 'react';
import { setSubscriber } from '../../../features/auth/authActions';
import { fetchTopics, listenToSelectedTopic, clearSelectedTopic } from '../../../features/topics/topicActions';
import { SET_REVIEW_INITIAL_STATE } from '../../../features/review/reviewConstants';
import { setReviewInitialState } from '../../../features/review/reviewActions';
import { FETCH_TOPICS, CLEAR_TOPICS } from '../../../features/topics/topicConstants';
import { SET_NOTES, CLEAR_NOTES } from '../../../features/notes/noteConstants';
import { RESET_STORE } from '../../store/rootConstants';
import { setSearchKeys } from '../../../features/search/searchActions';
import { identifyDiscrepancy } from '../../firestore/firestoreService';
import { consoleLog } from '../logging/logging';

export function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function getFileExtension(filename) {
  return filename.slice((filename.lastIndexOf('.') - 1 >>> 0) + 2);
}

export function createDataTree(dataset) {
  let hashtable = Object.create(null);
  dataset.forEach(a => hashtable[a.id] = { ...a, childNodes: [] });
  let dataTree = [];
  dataset.forEach(a => {
    if (a.parentId) hashtable[a.parentId].childNodes.push(hashtable[a.id]);
    else dataTree.push(hashtable[a.id]);
  });
  return dataTree;
}

export function noteBodyForSlate(note) {
  const sections = getNoteSectionsForFormatting(note, true, false)

  return getNoteForSlateFromSections(sections)
}

export function noteBodyJSX(note, spanClass, manualRangesOnly, howTo) {
  const sections = getNoteSectionsForFormatting(note, manualRangesOnly )

  return getNoteJSXFromSections(sections, spanClass, howTo)
}

export function getNoteForSlateFromSections(sections) {
  const children = sections.map((section) => {

    return { text: section.text, underline: section.important }
    
   })

  return [{
    type: 'paragraph',
    children: children,
  }]
}


export function getNoteJSXFromSections(sections, spanClass, howTo) {
  return sections.map((section, index) =>   {

    // The index is for the img key warning
    const textJSX = howTo ? getIconJSX(section.text, index) : section.text
    if (section.newLine) {
      return <span key={index}><br/></span>
    }
    if (section.important) {
      return <span key={index} className={spanClass}>{textJSX}</span>

    } else {
      return <span key={index}>{textJSX}</span>

    }
   } )
}


function textToNewlineJSX(text) {
  return text.split('\n').map(function(item, key) {
    return (
      <p>
        {item}
      </p>
    )
  })
}

function getIconJSX(text, key) {

  let addSplit = text.split("<<<addIcon>>>")
  if (addSplit.length == 2) {
    return [addSplit[0], <img key={key} src='/assets/addTopic.png' className='bodyInlineImage' />, addSplit[1]]
  }

  let notefuelSplit = text.split("<<<notefuelIcon>>>")
  if (notefuelSplit.length == 2) {
    return [notefuelSplit[0], <img key={key}  src='/assets/logo.png' className='bodyInlineImage' />, notefuelSplit[1]]
  }

  let ocrSplit = text.split("<<<ocrIcon>>>")
  if (ocrSplit.length == 2) {
    return [ocrSplit[0], <img key={key} src='/assets/image.png' className='bodyInlineImage' />, ocrSplit[1]]
  }

  return text
}

export function getNoteSectionsForFormatting(note, manualRangesOnly = false) {

  const body = note.body /// for testing + note.body + note.body

  if (!body) {
    return [{text: "", important: false}]
  }

  var sections = []
  var currentSectionText = ""
  var currentSectionIsImportant = false
  

  var ranges = effectiveRangesForNote(note, manualRangesOnly)


  for (let i = 0; i < body.length; i++) {

    if (body[i] == "\n") {
      sections.push({important: currentSectionIsImportant, text: currentSectionText})

      let isImportant = isIndexInRanges(i, ranges)
      sections.push({important: isImportant, text: "\n", newLine: true})
      currentSectionText = ""
      currentSectionIsImportant = isImportant
      continue;
    }

    if (isIndexInRanges(i, ranges)) {

      if (currentSectionIsImportant) {
        currentSectionText = currentSectionText + body[i]
      } else {
        sections.push({important: false, text: currentSectionText})
        currentSectionText = body[i]
        currentSectionIsImportant = true
      }

    } else {
      if (currentSectionIsImportant) {
        sections.push({important: true, text: currentSectionText})
        currentSectionText = body[i]
        currentSectionIsImportant = false
      } else {

        currentSectionText = currentSectionText + body[i]
      }
    }

  }

  sections.push({important: currentSectionIsImportant, text: currentSectionText})

  return  sections;

}


export function noteBodyWithUnderlineSpans(note, spanClass, manualRangesOnly = false, howTo = false) {

  const body = note.body /// for testing + note.body + note.body

  if (!body) {
    return ""
  }

  var finalString = ""

  var isItalicsSpanOpen = false
  var italicsSpanOpen = "<span class='" + spanClass + "'>"
  var italicsSpanClose = "</span>"

  // var italicsSpanOpen = "&lt;span class='bti'&gt;"
  // var italicsSpanClose = "&lt;/span&gt;"


  var ranges = effectiveRangesForNote(note, manualRangesOnly)


  for (let i = 0; i < body.length; i++) {

    if (isIndexInRanges(i, ranges)) {

      if (isItalicsSpanOpen) {
        finalString = finalString + body[i]
      } else {
        finalString = finalString + italicsSpanOpen + body[i]

        isItalicsSpanOpen = true
      }

    } else {
      if (isItalicsSpanOpen) {

        finalString = finalString + italicsSpanClose + body[i]
        isItalicsSpanOpen = false
      } else {

        finalString = finalString + body[i]
      }
    }

  }

  if (isItalicsSpanOpen) {
    finalString = finalString + italicsSpanClose
  }

  if (howTo) {
    return replaceHowToWithImageSpans(finalString)
  }

  return finalString;

}

export function replaceHowToWithImageSpans(html) {

  var replaced = html.replace("<<<addIcon>>>", "<span> <img src='/assets/addTopic.png' class='bodyInlineImage' /></span>")
  replaced = replaced.replace("<<<notefuelIcon>>>", "<span> <img src='/assets/logo.png' class='bodyInlineImage' /></span>")
  replaced = replaced.replace("<<<ocrIcon>>>", "<span> <img src='/assets/image.png' class='bodyInlineImage' /></span>")


  return replaced;

}

function isIndexInRanges(index, ranges) {

  for (let i = 0; i < ranges.length; i++) {
    let range = ranges[i]


    if (index >= range.location && index < (range.location + range.length)) {
      return true;
    }
  }

  return false;
}

export function createAutoUnderlinedNote(note) {

  if (!note.rich_body) {
    return note;
  }

  let autoUnderlinePhrase = getAutoUnderlinePhrase(note);

  if (!autoUnderlinePhrase) {
    return note;
  }

  note.rich_body = expandSetOfNodesWithAutoUnderlines(note.rich_body, autoUnderlinePhrase )

  // console.log("Created auto underlined note (" + autoUnderlinePhrase + "): " + JSON.stringify(note));

  return note
}

function expandSetOfNodesWithAutoUnderlines(nodes, autoUnderlinePhrase) {
  var newNodes = []

  for (let i = 0; i < nodes.length; i++) {

    let child = nodes[i]

    if (!isEmpty(child.text)) {
      let re = new RegExp(autoUnderlinePhrase, 'gi') 
      var split = child.text.split(re);


      if (split.length <= 1) {

        newNodes.push(child)

      } else {

        var startRangeToGetAppropriateCapitalization = 0

        // Insert an auto und
        for (let k = 0; k < split.length; k++) {
          let splitString = split[k]

          if ( splitString.length > 0) {
            
            newNodes.push ( {
              text: splitString,
              bold: child.bold,
              underline: child.underline,
              italic: child.italic
            } );

            startRangeToGetAppropriateCapitalization = startRangeToGetAppropriateCapitalization + splitString.length

          }

          // If we're not at the end,
          // add in an autoUnderlineNode, getting the correct capitalization
          if (k !== split.length - 1) {

            let properlyCapitalizedPhrase = child.text.substring(startRangeToGetAppropriateCapitalization, startRangeToGetAppropriateCapitalization + autoUnderlinePhrase.length)

            newNodes.push ( {
              text: properlyCapitalizedPhrase,
              bold: child.bold,
              underline: child.underline,
              italic: child.italic,
              autoUnderline: true
            } );

            startRangeToGetAppropriateCapitalization = startRangeToGetAppropriateCapitalization + properlyCapitalizedPhrase.length


          }


        }

      }
      //var split = str.split(/javascript/gi);

    } else {
      newNodes.push(autoUnderlineNodeAndChildren(child, autoUnderlinePhrase))
    }

  }

  return newNodes
}

function autoUnderlineNodeAndChildren(node, autoUnderlinePhrase) {


  if (node.children) {
    node.children = expandSetOfNodesWithAutoUnderlines(node.children, autoUnderlinePhrase);
  }

  return node;

}

export function getAutoUnderlinePhrase(note) {


  if (note.special_phrase) {
    return note.special_phrase
  }

  if (note.oa_cloze_deletion) {
    return note.oa_cloze_deletion
  }

  if (note.google_salient_phrase) {
    return note.google_salient_phrase
  }

}

export function richNoteHasUnderlines(note) {

  if (!note.rich_body) {
    return false;
  }

  const childrenValues = note.rich_body.map((child) => {
    return underlineInNodeOrChildren(child);
   })

   return childrenValues.some(function(child) {
    return child === true;
   });

}

function underlineInNodeOrChildren(node) {

  if (!isEmpty(node.text) && node.underline) {
    return true;
  }

  if (node.children) {
    const childrenValues = node.children.map((child) => {
      return underlineInNodeOrChildren(child);
     })

     return childrenValues.some(function(child) {
      return child;
     });
  }

  return false;

}

export function replaceGuideIcons(note) {

  if (!note.rich_body) {
    return false;
  }

  var newNodes = note.rich_body.map((child) => {
    return replaceGuideIconsInNodeAndChildren(child);
   })

   note.rich_body = newNodes


}

function replaceGuideIconsInNodeAndChildren(node) {

  if (!isEmpty(node.text)) {

    var text = node.text.replace("<<<addIcon>>> button", "+ button at the top")
    text = text.replace("<<<notefuelIcon>>> button", "Notefuel icon in the top right of the screen")
    text = text.replace("<<<ocrIcon>>> button", "image (leftmost) button within the note editor")

    node.text = text
    return node
  } else if (node.children) {
    var newNodes = node.children.map((child) => {
      return replaceGuideIconsInNodeAndChildren(child);
     })

     node.children = newNodes
  }

  return node
}


export function normalizeRichBodyQuirks(note) {

  if (!note.rich_body) {
    return;
  }

  note.rich_body = normalizeSetOfNodesWithQuirks(note.rich_body)

  if (note.rich_body.length === 0 || (note.rich_body.length == 1 && isEmpty(note.rich_body[0].type) && note.rich_body[0].text === "")) {

    note.rich_body = null
  }

  return note
}

function normalizeSetOfNodesWithQuirks(nodes) {
  var newNodes = []

  for (let i = 0; i < nodes.length; i++) {

    var node = nodes[i]

    // If we're an empty text node, just leave it out
    // Actually Can't do this bc there are paragraphs with empty text
    // and this causes a bunch of bad behavior/ bugs. Needy to allow
    // empty paragraphs
    // if (isEmpty(node.type) && node.text === "") {
    //   continue;
    // }

    if ((node.type === "bulleted-list") && node.children && node.children.length > 0) {

      let numberOfTextChildren = node.children.filter((child) => (isEmpty(child.type) && !isEmpty(child.text))).length

      consoleLog("Found  list with nOTC: " + numberOfTextChildren + " and note details: " + JSON.stringify(node));

      // If we're all text children, then wrap in a list item.
      if (numberOfTextChildren == node.children.length) {
        var newListItemNode = {
          type: "list-item",
          children: node.children,
        }
        node.children = [newListItemNode]

      }

    } else if ((node.type === "bulleted-list") && (!node.children || node.children.length == 0)) {

      node.children = [{
        type: "list-item",
        children: [{
          "text" : ""
        }],
      } ]


    } else if (node.type === "list-item" && (!node.children || node.children.length == 0)) {

      node.children = [{ "text" : ""} ]

    }

    if (node.children && node.children.length > 0) {
      let newChildren = normalizeSetOfNodesWithQuirks(node.children)
      node.children = newChildren

    }

    newNodes.push(node);

  }

  return newNodes
}





export function isEmpty(str) {
  return (!str || 0 === str.length);
}

function effectiveRangesForNote(note, manualRangesOnly = false) {

  if (note.manual_important_ranges && note.manual_important_ranges.length > 0) {
    return note.manual_important_ranges
  }

  if (manualRangesOnly) {
    return [];
  }

  if (note.oa_cloze_deletion) {
    let oaClozeRanges = ranges(note.body, note.oa_cloze_deletion)
    return oaClozeRanges

  }

  if (note.special_important_ranges && note.special_important_ranges.length > 0) {
    return note.special_important_ranges
  }

  if (note.google_salient_phrase) {
    let gspRanges = ranges(note.body, note.google_salient_phrase)
    return gspRanges

  }

  return []

}

function ranges(source, find) {
  if (!source || !find) {
    return [];
  }

  const findLength = find.length

  var result = [];
  for (let i = 0; i < source.length; ++i) {
    // If you want to search case insensitive use 
    // if (source.substring(i, i + find.length).toLowerCase() == find) {
    if (source.substring(i, i + find.length) == find) {
      result.push({ location: i, length: findLength });
    }
  }
  return result;
}

function compareReviews(a, b) {
  return a.completed_at.toDate() - b.completed_at.toDate();
}

export function overdueCount(topicSnapshot) {

  // if (topicSnapshot.id != "M4Bd6eDzmxKb8sQyEJ95") {
  //   return;
  // }
  //consoleLog("ODC topic: " + topicSnapshot.name)
  
  var noteCount = topicSnapshot.note_count
  
  if (typeof noteCount === 'undefined' || noteCount == 0) {
    return false
  }
  
  var reviewHistory = topicSnapshot.review_history

  if (!reviewHistory) {
    reviewHistory = {}
  }

  var overdueInHistoryCount = 0
  var notOverdueInHistoryCount = 0
  var excludedInHistoryCount = 0

  var excludedNotesCount = 0

  var excludedNotes = topicSnapshot.excluded_notes
  
  if (excludedNotes) {
    excludedNotesCount = excludedNotes.length
  } else {
    excludedNotes = []
  }


  
  for (var noteID in reviewHistory) {

    if (excludedNotes.includes(noteID)) {
      excludedInHistoryCount = excludedInHistoryCount + 1
      continue; // Act like this isn't even in the history
    }
      
    if (isNoteOverdue(reviewHistory[noteID])) {
      overdueInHistoryCount = overdueInHistoryCount + 1
    } else {
      notOverdueInHistoryCount = notOverdueInHistoryCount + 1

    }

  }

  var excludedNotInHistory = excludedNotesCount - excludedInHistoryCount

  var totalNotInHistory = noteCount - Object.keys(reviewHistory).length
  var totalOverdueNotInHistory = totalNotInHistory - excludedNotInHistory
  var totalOverdueNotes = overdueInHistoryCount + totalOverdueNotInHistory 
  
  // consoleLog("\t\ODC Excluded in history count:" + excludedInHistoryCount)
  // consoleLog("\t\tODC Note count:" + noteCount)
  // consoleLog("\t\tODC Not in history: " + totalNotInHistory)
  // consoleLog("\t\tODC Total overdue not in history: " + totalOverdueNotInHistory)
  // consoleLog("\t\tODC Total overdue: " + totalOverdueNotes)
  // consoleLog("\t\tODC Total overdue in history: " + overdueInHistoryCount)

  return totalOverdueNotes

}

export function overdueCountConsideringSpacedRepetitionSetting(topic) {
  if (topic.auto_spaced_repetition != true) {
    return 0;
  }
  return overdueCount(topic);
}

export function isNoteOverdue(reviewHistory) {
  if (!reviewHistory || reviewHistory.length < 1) {
    return true
  }

  var sortedHistory = reviewHistory.sort(compareReviews)

  var lastReview = sortedHistory[reviewHistory.length - 1]

  if (lastReview.sm2_interval == 0) {
    return true
  }

  const lastReviewTime = lastReview.completed_at

  if (lastReviewTime) {

        var nextReviewDate = lastReviewTime.toDate()

    // var nextReviewDate = new Date(lastReviewTime)
    nextReviewDate.setDate(nextReviewDate.getDate() + lastReview.sm2_interval)

    const currentDate = new Date()
    return currentDate > nextReviewDate
  }

  // Really shouldn't get here
  return false

}

export function createReview( grade, reviewHistory) {

  var newSm2Repetition = 0
  var newSm2Interval = 0
  var newSm2EasinessFactor = 2.5

  if (reviewHistory && reviewHistory.length > 0) {
    var reviewHistoryLast = reviewHistory[reviewHistory.length - 1]
    newSm2Repetition = reviewHistoryLast.sm2_repetition
    newSm2Interval = reviewHistoryLast.sm2_interval
    newSm2EasinessFactor = reviewHistoryLast.sm2_easiness_factor
  }


  if (grade < 3) {
    newSm2Repetition = 0
    newSm2Interval = 0
  } else {
    var qualityFactor = 5 - grade
    var potentialNewEasinessFactor = newSm2EasinessFactor + (0.1 - qualityFactor * (0.08 + qualityFactor * 0.02))
    if (potentialNewEasinessFactor < 1.3) {
      newSm2EasinessFactor = 1.3
    } else {
      newSm2EasinessFactor = potentialNewEasinessFactor
    }
    newSm2Repetition = newSm2Repetition + 1
    if (newSm2Repetition == 1) {
      newSm2Interval = 1
    } else if (newSm2Repetition == 2) {
      newSm2Interval = 6

    } else {
      var newIntervalDouble = Math.ceil((newSm2Repetition - 1) * newSm2EasinessFactor)

      newSm2Interval = Math.round(newIntervalDouble)
    }

  }

  if (grade == 3) {
    newSm2Interval = 0
  }


  var review = {
    completed_at: firebase.firestore.Timestamp.now(),
    grade: grade,
    sm2_easiness_factor: newSm2EasinessFactor,
    sm2_interval: newSm2Interval,
    sm2_repetition: newSm2Repetition
  }

  return review;
}


export function shuffleArray(array) {
  let i = array.length - 1;
  for (; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
}

export function getOverdueNotes(topic, notes) {


  let overdueNotes = []

  for (let i = 0; i < notes.length; i++) {
    let note = notes[i]
    if (!isNoteExcluded(topic, note.id)) {
      if (!topic.review_history) {
        overdueNotes.push(note)
      } else if (!topic.review_history[note.id]) {
        overdueNotes.push(note)

      } else if (isNoteOverdue(topic.review_history[note.id])) {
        overdueNotes.push(note)

      }
    }
  }

  return overdueNotes
}

export function getOverdueNotesConsideringSpacedRepetitionSetting(topic, notes) {
  if (topic.auto_spaced_repetition != true) {
    return [];
  }

  return getOverdueNotes(topic, notes);


}

export function getNextSuggestedReview(topic) {

  // If we don't have spaced repetition on, return never
  if (topic.auto_spaced_repetition != true) {

    return null;
  }

  if (!topic.review_history) {
    topic.review_history = {}
  }

  // This handles nice things like doing considering the exclude list
  if (overdueCount(topic) > 0) {
    return new Date()
  }

  var minSuggestedDate = null;
  for (const [key, value] of Object.entries(topic.review_history)) {
    const noteId = key
    const noteReviewHistory = value

    if (!isNoteExcluded(topic, noteId)) {
      let noteSuggestedDate = getNextReviewDateForSort(noteReviewHistory)
      if (!minSuggestedDate || noteSuggestedDate < minSuggestedDate) {
        minSuggestedDate = noteSuggestedDate
      }
    }
  }

  return minSuggestedDate


}


export function getNextReviewDateForSort(reviewHistory) {
  if (!reviewHistory || reviewHistory.length < 1) {
    return new Date();
  }

  var sortedHistory = reviewHistory.sort(compareReviews)

  var lastReview = sortedHistory[reviewHistory.length - 1]

  const lastReviewTime = lastReview.completed_at

  if (lastReview.sm2_interval == 0) {
    return lastReviewTime.toDate();
  }

  if (lastReviewTime) {

    var nextReviewDate = lastReviewTime.toDate()

    nextReviewDate.setDate(nextReviewDate.getDate() + lastReview.sm2_interval)
    return nextReviewDate;
  }

    // Really shouldn't get here
  return new Date();

}



export function isNoteExcluded(topic, noteId) {
  
  var excludedNotes = topic.excluded_notes

  if (!excludedNotes || excludedNotes.length == 0) {
    return false;
  }

  return excludedNotes.includes(noteId);
}

export function getRecentDaysOfReviews(topic) {

  let uniqueDates = {}
  let maxNumberOfNotes = 0
  if (!topic || !topic.review_history) {
    return []
  }

  for (const [noteId, reviewHistory] of Object.entries(topic.review_history)) {

    for (let i = 0; i < reviewHistory.length; i++) {
      let review = reviewHistory[i]
      let recalled = review.grade > 3

      let completedAtDate = review.completed_at.toDate();

      // let completedAtDate = new Date(review.completed_at)

      let dateOnly = format(completedAtDate, 'MM/dd/yy')

      var existingData =  uniqueDates[dateOnly]
      if (!existingData) {
        uniqueDates[dateOnly] = {
          date: completedAtDate,
          recalled: recalled ? 1 : 0,
          forgotten: recalled ? 0 : 1,
        }
      } else {
        if (recalled) {
          existingData.recalled = existingData.recalled + 1
        } else {
          existingData.forgotten = existingData.forgotten + 1

        }
        uniqueDates[dateOnly] = existingData


      }

      const maxNotesReviewedInADay = uniqueDates[dateOnly].recalled + uniqueDates[dateOnly].forgotten
      if (maxNotesReviewedInADay > maxNumberOfNotes) {
        maxNumberOfNotes = maxNotesReviewedInADay
      }

    }
  }

  let dayValues = Object.values(uniqueDates)
  let sorted = dayValues.sort(function(a, b) {
    return b.date - a.date;
  })
  let first = sorted.slice(0, 6)


  return {maxNumberOfNotes, recentDays: first}

}


export function getTopicStats(topic) {


  if (!topic || !topic.review_history) {
    return 0
  }

  let uniqueDates = {}
  let notesReviewed = 0

  for (const [noteId, reviewHistory] of Object.entries(topic.review_history)) {

    for (let i = 0; i < reviewHistory.length; i++) {
      let review = reviewHistory[i]


      //let completedAtDate = new Date(review.completed_at)
      let completedAtDate = review.completed_at.toDate()


      let dateOnly = format(completedAtDate, 'MM/dd/yy')
      uniqueDates[dateOnly] = true

      notesReviewed = notesReviewed + 1

    }
  }

  return { uniqueDates: Object.keys(uniqueDates).length, notesReviewed}

}

export function getNextReviewDateString(topic) {
  
  var nextReviewDate = "--"

  if (!topic) {
    return nextReviewDate;
  }

  if (topic.auto_spaced_repetition == true) {
    var nextSuggestedReview = getNextSuggestedReview(topic);

    if (nextSuggestedReview) {

      if (!(nextSuggestedReview > new Date())) {
        nextReviewDate = "ASAP"
      } else {
        nextReviewDate = format(nextSuggestedReview, 'MMM d')
      }

    }

  }

  return nextReviewDate;
}

export function clearStateAfterUserChanged(dispatch) {
  dispatch({ type: RESET_STORE, payload: [] })
   // dispatch({ type: CLEAR_NOTES, payload: [] })
  // dispatch({ type: CLEAR_TOPICS, payload: { topics: []} });
  // dispatch(clearSelectedTopic());
  // dispatch(setReviewInitialState())
}


export async function getSearchKeys(dispatch) {
  const functionRef = firebase.app().functions("us-central1").httpsCallable('getSearchKeyCallable');
  const { data } = await functionRef({});

  dispatch(setSearchKeys(data.user_key, data.sharing_key))
}


export function shuffleArr (array){
  for (var i = array.length - 1; i > 0; i--) {
      var rand = Math.floor(Math.random() * (i + 1));
      [array[i], array[rand]] = [array[rand], array[i]]
  }
}


// class func sortForTopic(topic : Topic, note1 : Note, note2 : Note) -> Bool {
        
//   let note1Index : Int? = note1.orderIndex(topic: topic)
//   let note2Index : Int? = note2.orderIndex(topic: topic)
//   print("Note indices: ", note1Index, note2Index)
//   if note1Index != nil {
//       if note2Index != nil {
//           return note1Index! > note2Index!
//       } else {
//           return false
//       }
      
//   } else {
//       if note2Index != nil {
//           return true
//       } else {
//           return note1.timeStamp() > note2.timeStamp()
//       }
//   }
  

// }

function getOrderIndex(topic, note) {

  if (topic.note_order == null) {
    return null
  }

  return topic.note_order[note.id]

}

export function sortNotesForTopic(note1, note2, topic) {

  //return b.created_at - a.created_at;

  let note1Index = getOrderIndex(topic, note1)
  let note2Index = getOrderIndex(topic, note2)

 // console.log("Note indices: ", note1Index, note2Index)
  if (note1Index != null) {
      if (note2Index != null) {
          return note2Index - note1Index
      } else {
          return 1
      }
      
  } else {
      if (note2Index != null) {
          return -1
      } else {
          return note2.created_at - note1.created_at
      }
  }

}