import escapeHtml from 'escape-html'
import { Text, Node } from 'slate'
import { noteBodyForSlate } from '../../app/common/util/util'


export const deserializeV1 = note => {
    var content = []

    // if (note.question) {

    //     let questionBlock = {
    //       type: 'question',
    //       children: [{ text: note.question}],
    //     }

    //     content.push(questionBlock);
    // }
    

    if (note.body) {
        
        content = content.concat(noteBodyForSlate(note));

    }

    if (content.length == 0) {
        content = [

            {
              type: 'paragraph',
              children: [{ text: '' }],
            },
          ]
    }

    return content;
}
