import {
    CREATE_NOTE,
    UPDATE_NOTE,
    DELETE_NOTE,
    FETCH_NOTES,
    LISTEN_TO_NOTE_CHAT,
    LISTEN_TO_SELECTED_NOTE,
    CLEAR_NOTES,
    SET_FILTER,
    SET_START_DATE,
    CLEAR_SELECTED_NOTE,
  } from './noteConstants';
  import {
    asyncActionStart,
    asyncActionFinish,
    asyncActionError,
  } from '../../app/async/asyncReducer';
  import {
    fetchNotesFromFirestore,
    dataFromSnapshot,
    convertV1NoteToRichV2,
  } from '../../app/firestore/firestoreService';
  
  export function fetchNotes(selectedTopic, notesStartDate, limit, lastDocSnapshot) {

    return async function (dispatch) {
      dispatch(asyncActionStart());

      try {
        const snapshot = await fetchNotesFromFirestore(
          selectedTopic,
          limit,
          lastDocSnapshot
        ).get();
        const notesLastVisible = snapshot.docs[snapshot.docs.length - 1];
        const moreNotes = snapshot.docs.length >= limit;

        // const notes = snapshot.docs.map((doc) => dataFromSnapshot(doc));

        
        const notes = snapshot.docs.map((doc) => convertV1NoteToRichV2(dataFromSnapshot(doc)));


        dispatch({ type: FETCH_NOTES, payload: { notes, moreNotes, notesLastVisible } });
        dispatch(asyncActionFinish());
      } catch (error) {
        dispatch(asyncActionError(error));
      }
    };
  }
  
  export function setFilter(value) {
    return function(dispatch) {
      dispatch(clearNotes());
      dispatch({type: SET_FILTER, payload: value})
    }
  }
  
  export function setStartDate(date) {
    return function(dispatch) {
      dispatch(clearNotes());
      dispatch({type: SET_START_DATE, payload: date})
    }
  }
  
  export function listenToSelectedNote(note) {
    return {
      type: LISTEN_TO_SELECTED_NOTE,
      payload: note,
    };
  }
  
  export function clearSelectedNote() {
    return {
      type: CLEAR_SELECTED_NOTE
    }
  }
  
  export function createNote(note) {
    return {
      type: CREATE_NOTE,
      payload: note,
    };
  }
  
  export function updateNote(note) {
    return {
      type: UPDATE_NOTE,
      payload: note,
    };
  }
  
  export function deleteNote(noteId) {
    return {
      type: DELETE_NOTE,
      payload: noteId,
    };
  }
  
  export function listenToNoteChat(comments) {
    return {
      type: LISTEN_TO_NOTE_CHAT,
      payload: comments,
    };
  }
  
  export function clearNotes() {
    return {
      type: CLEAR_NOTES,
    };
  }
  