import { sendAmplitudeData } from "./amplitude"
import { analytics } from "../../config/firebase"

export function logEvent(eventName, properties) {
  consoleLog("Logging event: " + eventName + " - " + JSON.stringify(properties))

  var firebaseEventName = eventName.replaceAll(" ", "_");

  if (process.env.NODE_ENV === 'production') {
    sendAmplitudeData(eventName, properties)
    analytics().logEvent(firebaseEventName, properties)
  }

}

export function logError(error) {
    consoleError("Logging error: " + JSON.stringify(error))
  }


export function consoleLog(obj) {
  if (process.env.NODE_ENV !== 'production') {
    console.log(obj)
  }
}

export function consoleError(obj) {
  if (process.env.NODE_ENV !== 'production') {
   console.error(obj)
  }
}
