import React, { useEffect } from 'react';
import ModalWrapper from '../../app/common/modals/ModalWrapper';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import MyTextInput from '../../app/common/form/MyTextInput';
import { Button, Label, Image } from 'semantic-ui-react';
import { useDispatch } from 'react-redux';
import { closeModal, openModal } from '../../app/common/modals/modalReducer';
import { updateUserEmail, sendVerificationEmail } from '../../app/firestore/firebaseService';
import firebase from '../../app/config/firebase';
import { toast } from 'react-toastify';
import { setNeedsWelcomeInFirestore } from '../../app/firestore/firestoreService';
import { logEvent } from '../../app/common/logging/logging';

export default function EmailSolicitation({setShowEmailSolicitation}) {
    const dispatch = useDispatch();

    async function close() {
        setShowEmailSolicitation(false);

    }

    async function signIn() {
        setShowEmailSolicitation(false);
        dispatch(openModal({ modalType: 'LoginForm', modalProps: {} }))
    }


    useEffect(() => {
        setNeedsWelcomeInFirestore(false);
        logEvent("Topic Opening View Seen")

    }, []);


    return (
        <div style={{backgroundColor: "#FFF", borderRadius: 10, color: "#3F3F3F", padding: 16, position: "relative"}}>

        <Button onClick={close} style={{ left: 5, top: 6, position: "absolute", height: 36, width: 36, padding: 0, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", backgroundColor: "#FAFAFA" }}>
        <img src='/assets/close_x.png' alt='logo' style={{ height: 13, width: 13 }} />
      </Button>

            <div style={{  display: "flex", justifyContent: "center", paddingTop: 0, paddingBottom: 8 }}>

                <Image
                    src='/assets/logo.png'
                    style={{ height: 46.92, width: 40, paddingTop: 0, paddingBottom: 6 }}
                />
            </div>
            <div className="titleTextFont" style={{fontSize: 17, textAlign: "center"}}>
            Welcome to Notefuel!
            </div>
            
            <div style={{fontSize: 13, textAlign: "center", paddingTop: 8, paddingBottom: 16}}>
                {/* We created a free Notefuel account for you - add an email so you can re-access it later. */}

                We've signed you into a fresh account - add an email so you can access these notes later.

            </div>
            <Formik
                initialValues={{ email:  '' }}
                validationSchema={Yup.object({
                    email: Yup.string().required().email()
                })}
                onSubmit={async (values, { setSubmitting, setErrors }) => {
                    try {
                        await updateUserEmail(values);
                        setSubmitting(false);
                        setShowEmailSolicitation(false);

                        sendVerificationEmail();

                    } catch (error) {

                        // if (error.code === "auth/requires-recent-login") {
                        //     toast.error(error.message);

                        // } else {
                        //     setErrors({auth: 'Email is invalid.'});
                        // }
                        setErrors({ auth: error.message });

                        setSubmitting(false);
                    }
                }}
            >
                {({ isSubmitting, isValid, dirty, errors }) => (
                    <Form className='ui form' style={{  display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                        <MyTextInput autoFocus name='email' placeholder={'Email address'} style={{marginBottom: 0}}/>
                        {errors.auth && <Label basic color='red' style={{ marginBottom: 10 }} content={errors.auth} />}
                        <Button className="secondaryButton normalTextFont"
                            style={{  height: 40, width: 135, borderRadius: 22, fontSize: 14 }}
                            loading={isSubmitting}
                            disabled={false } //!isValid || !dirty || isSubmitting}
                            type='submit'
                            fluid
                            size='large'
                            content='Add email'
                        />

                    </Form>
                )}
            </Formik>
            <div style={{fontSize: 13, textAlign: "center", paddingTop: 24}}>

                    Already have an account? <a onClick={signIn} className="titleTextFont" style={{  color: "#3F3F3F", cursor: "pointer" }}>Sign in</a>
                </div>
   
        </div>
    )
}