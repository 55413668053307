import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Segment, Menu, Checkbox, Item, Button, Select, Grid } from 'semantic-ui-react';
import ModalWrapper from '../../app/common/modals/ModalWrapper';
import { closeModal } from '../../app/common/modals/modalReducer';
import { setAutoflashcardsEnabled, setRandomOrder, setNumberOfNotes, setReviewType, fetchReviewingNotes } from './reviewActions';
import { useEffect } from 'react';
import FlashcardFront from './FlashcardFront';
import FlashcardBack from './FlashcardBack';
import { getRichBodyForReview } from '../../app/common/util/util';

export default function FlashcardWrapper({ reviewType, showCurrentAnswer, currentNoteIndex, selectedNotes, autoflashcardsEnabled, isHowTo  }) {    

    return (
<div className="flashcardWrapper flashcardWithFixedHeight" style={{ top: 0, left: 0, width: "100%", position: "absolute", paddingLeft: 20, paddingRight: 20}}>
        <div
            className={
                showCurrentAnswer ? "flip-container hover" : "flip-container"
            }
        >
            <div className='flipper'>
                <div className='front'>
                <Grid verticalAlign='middle' columns={1} centered style={{ backgroundColor: "#FFF"}} className="customCard flashcard flashcardWithFixedHeight" >
                            <Grid.Row centered style={{ maxHeight: "100%", overflowY: "auto"}}>
                                <Grid.Column style={{ maxHeight: "100%", overflowY: "auto"}}>
                                    <FlashcardFront style={{ maxHeight: "100%", overflowY: "auto"}} note={selectedNotes[currentNoteIndex]} autoflashcardsEnabled={autoflashcardsEnabled} isHowTo={isHowTo}/> 
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                </div>
                <div className='back'>
                         <Grid verticalAlign='middle' columns={1} centered style={{ backgroundColor: "#FFF"}} className="customCard flashcard flashcardWithFixedHeight" >
                            <Grid.Row centered style={{ maxHeight: "100%", overflowY: "auto"}}>
                                <Grid.Column style={{ maxHeight: "100%", overflowY: "auto"}}>
                            <FlashcardBack style={{ maxHeight: "100%", overflowY: "auto"}} note={selectedNotes[currentNoteIndex]} autoflashcardsEnabled={autoflashcardsEnabled} isHowTo={isHowTo}/> 
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                </div>
            </div>
        </div>
        </div>





    )
}