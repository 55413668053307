import escapeHtml from 'escape-html'
import { Text, Node } from 'slate'


export const htmlSerialize = node => {
  if (Text.isText(node)) {

    var openingTags = ""
    var closingTags = ""

    if (node.underline) {
      openingTags = "<u>"
      closingTags = "</u>"
    }

    if (node.bold) {
      openingTags = openingTags + "<strong>"
      closingTags = "</strong>" + closingTags
    }

    if (node.italic) {
      openingTags = openingTags + "<em>"
      closingTags = "</em>" + closingTags
    }    


    return openingTags + escapeHtml(node.text).replace("\n", "<br/>") + closingTags
  }

  const children = (node.children ?? []).map(n => htmlSerialize(n)).join('')

  switch (node.type) {
    case 'quote':
      return `<blockquote><p>${children}</p></blockquote>`
    case 'paragraph':
      return `<p>${children}</p>`
    case 'numbered-list':
        return `<ol>${children}</ol>`      
    case 'bulleted-list':
      return `<ul>${children}</ul>`          
    case 'list-item':
      return `<li>${children}</li>`            
    case 'cloze':
      return `<a href="">${children}</a>`
    default:
      return children
  }
}

export const manualImportantRangeSerialize = nodes => {

    let finalString = ""
    let manualImportantRanges = []
    for (let i = 0; i < nodes.length; i++) {
      let node = nodes[i]
      let string = Node.string(node)

      if (node.type == "paragraph" && i > 0) {
        string = "\n" + string
      }

      let startIndex = finalString.length
      finalString = finalString + string

      var childOffset = 0
      for (let k = 0; k < node.children.length; k++) {
        let child = node.children[k]

        let textLength = (child.text ? child.text.length : 0)
        if (child.underline === true) {
          manualImportantRanges.push({
            length: textLength,
            location: startIndex + childOffset
          })
        }
        childOffset = childOffset + textLength


      }
    }

    return { body: finalString, manualImportantRanges }

  }

  // This would ideally be done recursively, to support any stragne edge cases around
  // nested lists and make sure it is really future proof. But this is complicated by the need
  // to keep track of closure, so you can assume shallow tree for now and normalize if necessary.
  export const plainTextAndClozeSerialize = nodes => {

    let finalString = ""
    let clozeRanges = []
    for (let i = 0; i < nodes.length; i++) {
      let node = nodes[i]
      let string = Node.string(node)

      if (node.type === "question") {
        continue;
      }

      if (node.type == "paragraph" && i > 0) {
        string = "\n" + string

        let startIndex = finalString.length
        finalString = finalString + string
  
        var childOffset = 0
        for (let k = 0; k < node.children.length; k++) {
          let child = node.children[k]
  
          let textLength = (child.text ? child.text.length : 0)
          if (child.cloze === true) {
            clozeRanges.push({
              length: textLength,
              location: startIndex + childOffset
            })
          }
          childOffset = childOffset + textLength
  
        }

      }

      // Still need to handle list items
      if ((node.type == "numbered-list" || node.type == "bulleted-list") && i > 0) {

        // Iterate through what should really only be the list items
        // of this list.
        for (let j = 0; j < node.children.length; j++) {

          // Now, add a new line for each list item, and then add a bullet or a number depending on the list item.

          const bulletString = node.type == "numbered-list" ? (j + 1) + ". " : "• "

          let listItem = node.children[j]
          let listItemString = "\n" + bulletString + Node.string(listItem)

          let startIndex = finalString.length
          finalString = finalString + listItemString
    
          var listItemChildOffset = 0          

          for (let k = 0; k < listItem.children.length; k++) {
            let child = listItem.children[k]
    
            let textLength = (child.text ? child.text.length : 0)
            if (child.cloze === true) {
              clozeRanges.push({
                length: textLength,
                location: startIndex + listItemChildOffset
              })
            }
            listItemChildOffset = listItemChildOffset + textLength
          }

  
        }

      }


    }

    return { plainText: finalString, clozeRanges: clozeRanges }

  }


  export const plainTextSerialize = nodes => {

    var string = ""
    for (let i = 0; i < nodes.length; i++) {
      let node = nodes[i]
      let string = Node.string(node)

      if (node.type === "question") {
        continue;
      }

      if (node.type == "paragraph" && i > 0) {
        string = "\n" + string

      } else if ((node.type == "numbered-list" || node.type == "bulleted-list") && i > 0) {

        string = "\n" + string


      } else {
        string = " " + string

      }


    }

    return string

  }