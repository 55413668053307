import {
    SET_AUTOFLASHCARDS,
    SET_REVIEW_TYPE,
    SET_RANDOM_ORDER,
    SET_NUMBER_OF_NOTES,
    FETCH_REVIEWING_NOTES,
    SET_SHOW_CURRENT_ANSWER,
    SET_STAGE,
    SET_CURRENT_NOTE_INDEX,
    SET_SELECTED_NOTES,
    SET_REVIEW_INITIAL_STATE,
    UPDATE_SELECTED_NOTE, PREVIOUS_CARD, NEXT_CARD, FLIP_CARD,
    RESTART_REVIEW_FORGOTTEN,
    RESTART_REVIEW_AGAIN
  } from './reviewConstants';
import { dataFromSnapshot, fetchNotesFromFirestore, convertV1NoteToRichV2 } from '../../app/firestore/firestoreService';
import { asyncActionStart, asyncActionFinish, asyncActionError } from '../../app/async/asyncReducer';
import { richNoteHasUnderlines, createAutoUnderlinedNote, getAutoUnderlinePhrase, isEmpty } from '../../app/common/util/util';

  
  export function setAutoflashcardsEnabled(value) {
    return {
      type: SET_AUTOFLASHCARDS,
      payload: value,
    };
  }
  
  export function setReviewType(value) {
    return {
      type: SET_REVIEW_TYPE,
      payload: value,
    };
  }
  

  export function setRandomOrder(value) {
    return {
      type: SET_RANDOM_ORDER,
      payload: value,
    };
  }
  

  export function setNumberOfNotes(value) {
    return {
      type: SET_NUMBER_OF_NOTES,
      payload: value,
    };
  }

  export function setShowCurrentAnswer(value) {
    return {
      type: SET_SHOW_CURRENT_ANSWER,
      payload: value,
    };
  }
  
  export function setStage(value) {
    return {
      type: SET_STAGE,
      payload: value,
    };
  }
  
  export function setCurrentNoteIndex(value) {
    return {
      type: SET_CURRENT_NOTE_INDEX,
      payload: value,
    };
  }

  export function setSelectedNotes(value) {
    return {
      type: SET_SELECTED_NOTES,
      payload: value,
    };
  }

  export function setReviewInitialState() {
    return {
      type: SET_REVIEW_INITIAL_STATE
      };
  }

  export function updateSelectedNote(value) {
    return {
      type: UPDATE_SELECTED_NOTE,
      payload: value,
    };
  }
  
  export function flipCard() {
    return {
      type: FLIP_CARD
      };
  }


  export function nextCard(recalled) {
    return {
      type: NEXT_CARD,
      payload: recalled
      };
  }


  export function previousCard() {
    return {
      type: PREVIOUS_CARD
      };
  }

  export function restartReviewAgain() {
    return {
      type: RESTART_REVIEW_AGAIN
      };
  }

  export function restartReviewForgotten() {
    return {
      type: RESTART_REVIEW_FORGOTTEN
      };
  }

  export function fetchReviewingNotes(selectedTopic) {
    return async function (dispatch) {
      dispatch(asyncActionStart());

      try {
        const snapshot = await fetchNotesFromFirestore(
          selectedTopic,
          5000
        ).get();

        var notes = snapshot.docs.map((doc) => convertV1NoteToRichV2(dataFromSnapshot(doc)));

        for (let i = 0; i < notes.length; ++i) {
          
          var note = notes[i]

          note.hasUnderlines = richNoteHasUnderlines(note)

          var autoUnderlinePhrase = getAutoUnderlinePhrase(note)

          note.hasAutoUnderlines = autoUnderlinePhrase && !isEmpty(autoUnderlinePhrase);


          // If it doesn't have underlines, then create auto_uunderlines
          if (!note.hasUnderlines && note.hasAutoUnderlines) {

            notes[i] = createAutoUnderlinedNote(note);
          }

        }

        dispatch({ type: FETCH_REVIEWING_NOTES, payload: {notes, topic: selectedTopic }});
        dispatch(asyncActionFinish());
      } catch (error) {
        dispatch(asyncActionError(error));
      }
    };
  }

