import React from 'react';

import {  Grid, Button, Popup, Loader } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { Editable, withReact, useSlate, Slate, useSelected, useFocused, ReactEditor } from 'slate-react'
import { Editor, Transforms, createEditor, Node, Range, Text, Element as SlateElement } from 'slate'
import { useState } from 'react';
import { useCallback } from 'react';
import { useMemo } from 'react';
import { withHistory } from 'slate-history'
import isHotkey from 'is-hotkey'
import { buildNote, addNoteToFirestore, updateNoteInFirestore, incrementNoteCount, incrementTextScanCount, buildRichNote } from '../../app/firestore/firestoreService';
import { closeModal, openModal } from '../../app/common/modals/modalReducer';
import { noteBodyJSX, noteBodyForSlate } from '../../app/common/util/util';
import { useDropzone } from 'react-dropzone'
import ImageEditor from './ImageEditor';
import { useEffect } from 'react';
import firebase, { remoteConfig } from '../../app/config/firebase'
import { toast } from 'react-toastify';
import { deleteFromFirebaseStorage } from '../../app/firestore/firebaseService';
import { Point } from 'slate'
import { logEvent } from '../../app/common/logging/logging';
import { incrementTextScanCountLocally } from '../auth/authActions';
import {Detector} from "react-detect-offline";
import { withLists, indentItem, undentItem } from "@york-ie-labs/slate-lists";
import escapeHtml from 'escape-html'
import ClozeEditor from './ClozeEditor';
import { manualImportantRangeSerialize, htmlSerialize, plainTextAndClozeSerialize, plainTextSerialize } from './serialization';
import { deserializeV1 } from './deserialization';

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
 // 'mod+/': 'question',
  'shift+enter': 'save',
 // 'shift+c': 'cloze',
 // 'mod+`': 'code',

}

const LIST_TYPES = ['numbered-list', 'bulleted-list']

const questionSerialize = nodes => {
  return nodes.map(n => Node.string(n)).join('\n')
}

const bodySerialize = nodes => {
  return nodes.map(n => Node.string(n)).join('\n')
}

export default function NoteEditor({ editingNote }) {

  const dispatch = useDispatch();

  const [submitting, setSubmitting] = useState(false);
  const [uploadedImage, setUploadedImage] = useState((editingNote && editingNote.image_attachment_bucket) && { bucket: editingNote.image_attachment_bucket, fullPath: editingNote.image_attachment_full_path, filename: editingNote.image_attachment_filename });
  const [uploadedImageDimensions, setUploadedImageDimensions] = useState(null);
  const [imageDownloadURL, setImageDownloadURL] = useState(null);
  const { currentUserProfile } = useSelector((state) => state.profile);

  const { isSubscriber } = useSelector((state) => state.auth);
  const { selectedTopic, userTotalNoteCount } = useSelector((state) => state.topic);
  const { sidebarVisible } = useSelector(state => state.sidebar);
  const [editingCloze, setEditingCloze] = useState(null);

  const focused = useFocused();

  async function updateCloze(path, wrongAnswers) {

    console.log("Update cloze with value: " + JSON.stringify(value));
    console.log("Update cloze with path: " + path)
  

    Transforms.setNodes(editor, { wrongAnswers: wrongAnswers }, { at: path })

  }



  useEffect(() => {

    if (uploadedImage && uploadedImage.fullPath) {
      const storageRef = firebase.storage().ref();
      storageRef.child(uploadedImage.fullPath).getDownloadURL().then(downloadURL => {
        setImageDownloadURL(downloadURL);
      })
    }

    return () => { }
  }, [uploadedImage]);

  var initialQuestionValue;
  var initialBodyValue;

  if (editingNote) {

    if (editingNote.question) {

      initialQuestionValue = [{
        type: 'paragraph',
        children: [{ text: editingNote.question }],
      }]

    } else {
      initialQuestionValue = initialEmptyValue;
    }

    // If you're not doing the remapping in the fetch call (i.e., in noteactions or reviewactions),
    // // then you need to do this here.
    // if (editingNote.format_version === 2) {
    //   initialBodyValue = editingNote.rich_body ?? initialEmptyValue
    // } else {
      
    //   initialBodyValue = deserializeV1(editingNote);
    // }

    initialBodyValue = editingNote.rich_body ?? initialEmptyValue

  } else {
    initialQuestionValue = initialEmptyValue;
    initialBodyValue = initialEmptyValue;
  }


  const [showQuestion, setShowQuestion] = useState(editingNote && editingNote.question_type == "question" && editingNote.question)

  const [imageURL, setImageURL] = useState(null)

  const [ocrLoading, setOcrLoading] = useState(false);
  const [imageAttachmentLoading, setImageAttachmentLoading] = useState(false);

  const [imagePopupOpen, setImagePopupOpen] = useState(false);

  function handleImagePopupOpen() {
    setImagePopupOpen(true);
  }

  function handleImagePopupClose() {
    setImagePopupOpen(false);
  }


  const [questionValue, setQuestionValue] = useState(initialQuestionValue)
  const questionEditor = useMemo(() => withHistory(withReact(createEditor())), [])

  const [value, setValue] = useState(initialBodyValue)
  const renderElement = useCallback(props => <CustomElement {...props} />, [])
  const renderLeaf = useCallback(props => <CustomLeaf {...props} setEditingCloze={setEditingCloze} />, [])

  const editor = useMemo(
    () => withHistory(withReact(createEditor())),
    []
  )

  const setEditorToEnd = () => {
    let editorEnd = Editor.end(editor, []);
      Transforms.select(editor, { anchor: editorEnd, focus: editorEnd });
    
  }

  const setQuestionEditorToEnd = () => {
    console.log("Setting question editor: " + questionEditor.value + "|||" + JSON.stringify(questionValue))
    let editorEnd = Editor.end(questionEditor, []);
      Transforms.select(questionEditor, { anchor: editorEnd, focus: editorEnd });
    
  }


  // If you only want to run the function given to useEffect after the initial render, you can give it an empty array as second argument.
  useEffect(() => {
    // const start = {
    //   path: [0, 0],
    //   offset: 0,
    // }

    // Transforms.select(editor, start);
    setEditorToEnd();
  }, []);



  let editorIsFocused = ReactEditor.isFocused(editor);

  useEffect(() => {

    if (editorIsFocused && editingCloze) {
      setEditingCloze(null);
    }
    return () => { }
  }, [editorIsFocused]);


  const imgElement = React.useRef(null);


  function stopTextScanIfNecessary() {
    if (!editingNote &&!isSubscriber && currentUserProfile && currentUserProfile.text_scan_count && currentUserProfile.text_scan_count >= remoteConfig().getValue('textScanLimit').asNumber()) {

      dispatch(closeModal);
      dispatch(openModal({
        modalType: 'SubscribeWall', modalProps: {
          subWall: true,
          title: "Upgrade to Notefuel Premium",
          subTitle: "You've reached your text scan limit (" + remoteConfig().getValue('textScanLimit').asNumber() + ")."
        }
      }))

      logEvent("Sub Wall Hit Note Limit")
      return true;
    } else {
      return false;
    }
  }


  async function ocrExistingImage() {

    setImagePopupOpen(false);

    if (stopTextScanIfNecessary()) {
      return;
    }    

    ocrImage(uploadedImage.filename, false)
    logEvent("Editor OCR Existing Image Tapped")
  }

  async function ocrNewImage(filename) {
    ocrImage(filename, true)
    logEvent("Editor OCR Image Tapped")

  }

  async function ocrImage(filename, deleteAfter) {

    setOcrLoading(true);

    const functionRef = firebase.app().functions("us-central1").httpsCallable('ocrScanText');
    const { data } = await functionRef({ filename: filename });

    setOcrLoading(false);

    var ocrText = data.replace("-\n ", "").replace("-\n", "").replace("\n", " ").replace("- ", "");

    if (ocrText && ocrText.match(/^\s+$/) === null) {

      Transforms.insertNodes(editor,
        [{
          text: ocrText,
        }]
      )

      incrementTextScanCount();
    } else {
      toast.info("No text was found in the image.")
    }

    if (deleteAfter) {
      deleteFromFirebaseStorage(filename)
    }


  }

  async function removeUploadedImage() {
    deleteFromFirebaseStorage(uploadedImage.filename)

    setUploadedImage(null)
    setUploadedImageDimensions(null)
    logEvent("Editor Remove Image Tapped")

  }


  const isEmpty = (value.map(n => Node.string(n)).join('').length == 0) && !uploadedImage

  
  function questionTapped() {
    return;
    logEvent("Editor Question Button Tapped")

    console.log("Question value: " + JSON.stringify(questionValue));
   


    if (showQuestion) {
      setShowQuestion(false);

      ReactEditor.focus(editor);

      setEditorToEnd()


      // setTimeout(async function () {

      //   const editorEl = document.querySelector('#questionEditableSlate');
      //   editorEl.focus();
      // }, 1000);

    } else {

      setShowQuestion(true);


      ReactEditor.focus(questionEditor);

      setQuestionEditorToEnd()


      // setTimeout(async function () {

      //   const editorEl = document.querySelector('#bodyEditableSlate');
      //   editorEl.focus();
      // }, 1000);

    }


  }

  const serialize = node => {
    if (Text.isText(node)) {
      return escapeHtml(node.text)
    }
  
    const children = node.children.map(n => serialize(n)).join('')
  
    switch (node.type) {
      case 'quote':
        return `<blockquote><p>${children}</p></blockquote>`
      case 'paragraph':
        return `<p>${children}</p>`
      case 'link':
        return `<a href="${escapeHtml(node.url)}">${children}</a>`
      default:
        return children
    }
  }

  async function saveTapped() {
    // console.log("Selection: " + JSON.stringify(editor.selection));

    // console.log("Value: " + JSON.stringify(value));
    // return;

    // For the keyboard case, don't submit if we're empty
    if (isEmpty) {
      return;
    }

    if (!editingNote && !isSubscriber && userTotalNoteCount >= remoteConfig().getValue('noteLimit').asNumber()) {

      dispatch(closeModal);
      
      dispatch(openModal({ modalType: 'SubscribeWall', modalProps: { subWall: true,
        subTitle: "You've reached your plan's note limit (" + remoteConfig().getValue('noteLimit').asNumber() + ")." } }))

        logEvent( "Sub Wall Hit Note Limit")
      return;
    }

    setSubmitting(true)

    const serializedQuestion =  questionSerialize(questionValue)
    var serializedQuestionCopy =  serializedQuestion
    const questionText = !serializedQuestionCopy.trim().length ? null : serializedQuestion
    // const { body, manualImportantRanges } = manualImportantRangeSerialize(value)
    //const { plainText, clozeRanges } = plainTextAndClozeSerialize(value);

    const plainText = bodySerialize(value);

    var plainTextCopy =  plainText

    

    // console.log("Plain text body: " + plainText)
    // console.log("Important ranges: " + JSON.stringify(clozeRanges));
    
    // for (var range in manualImportantRanges) {
    //   console.log("\tCloze: " + body[range.location, range.location + range.length])
    // }
    setSubmitting(false);

 //   return;
    
//    const note = buildNote(editingNote, selectedTopic, body, questionText, manualImportantRanges, uploadedImage, uploadedImageDimensions, isSubscriber)

    const note = buildRichNote(editingNote, selectedTopic, value, plainText, questionText, [], uploadedImage, uploadedImageDimensions, isSubscriber)

  //   console.log("Plain text:" + plainText + "|" + plainTextCopy);
   //  console.log("SerializedQuestion text:" + serializedQuestion + "|" + serializedQuestionCopy);

    if (!plainTextCopy.trim().length) {
      note.body = ""
      note.rich_body = null
    } else {
      note.body = plainText
    }

    //note.body = plainText


    // var htmlSerialized
    // if (Array.isArray(value)) {
    //   htmlSerialized = htmlSerialize({children: value})

    // } else {
    //   htmlSerialized = htmlSerialize(value)

    // }
   // note.html = htmlSerialized
   // console.log("Serialized html: " + htmlSerialized)

    //return;

    if (!editingNote) {
      logEvent("Created Note")

      addNoteToFirestore(note);
      incrementNoteCount(selectedTopic.id, 1);
      Transforms.deselect(editor)
      Transforms.deselect(questionEditor)

      //setShowQuestion(false);


      setUploadedImage(null)
      setUploadedImageDimensions(null)
      setValue(initialEmptyValue)
      setQuestionValue(initialEmptyValue)

      setSubmitting(false);

      const start = {
        path: [0, 0],
        offset: 0,
      }

      Transforms.select(editor, start);
    } else {
      logEvent("Updated Note")

      updateNoteInFirestore(note);
      dispatch(closeModal());
      setSubmitting(false);
    }
  }


  const [files, setFiles] = useState([]);
  const [image, setImage] = useState(null);

  const onDrop = useCallback(acceptedFiles => {
    setFiles(acceptedFiles.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file)
    })))
  }, [setFiles])
  const { fileRejections, getRootProps, getInputProps, open, isDragActive } = useDropzone({ onDrop, noClick: true, accept: 'image/jpeg, image/png' })

  const dropzoneStyles = {
    // border: 'dashed 3px #eee',
    // borderRadius: '5%',
    //textAlign: 'center'
  }

  const dropzoneActive = {
    border: 'dashed 1px #EF4136'
  }

  function handleOfflineMessage() {
    toast.info("Attaching images and scanning text is not supported in offline mode on web. Otherwise, Notefuel will work normally offline.")
    logEvent("Image Offline Message")

  }



  return (
    <div
      className='dz'
      {...getRootProps()}
      style={
        isDragActive
          ? { ...dropzoneStyles, ...dropzoneActive }
          : dropzoneStyles
      }
    >
      <input {...getInputProps()} />

      <Grid
        verticalAlign='middle'
        columns={1}
        centered
        style={{
          backgroundColor: "#FFF",
          fontSize: 15,
          margin: 0,
          boxShadow: "0 4px 20px 0 rgba(0,0,0,0.1)",
          borderRadius: 10,
        }}
        className=''
      >

        {files.length > 0 ? (

          <ImageEditor
            files={files}
            setFiles={setFiles}
            setUploadedImage={setUploadedImage}
            setUploadedImageDimensions={setUploadedImageDimensions}
            setImageDownloadURL={setImageDownloadURL}
            ocrNewImage={ocrNewImage}
            setOcrLoading={setOcrLoading}
            setImageAttachmentLoading={setImageAttachmentLoading}
            stopTextScanIfNecessary={stopTextScanIfNecessary}
            handleOfflineMessage={handleOfflineMessage}
          ></ImageEditor>

        ) :
          (
            <>

{true && (
                <Grid.Row centered style={{ paddingTop: 20, paddingBottom: 0 }}>
                  <Grid.Column style={{}}>
                    <div
                      style={{
                        background: "#FAFAFA",
                        borderRadius: "20px",
                        paddingLeft: 12,
                        paddingRight: 12,
                        paddingTop: 8,
                        paddingBottom: 8,
                        fontSize: 13,
                        border: "1px solid #F1F1F1"
                      }}
                    >
                      <Slate
                        id="questionEditorSlate"
                        editor={questionEditor}
                        value={questionValue}
                        onChange={(value) => setQuestionValue(value)}
                      >
                        <div style={{ maxHeight: "12vh", overflowY: "scroll" }}>
                          <Editable id="questionEditableSlate" placeholder='Add a question or key phrase'
                                                  renderElement={renderElement}
                                                  renderLeaf={renderLeaf}
                          onKeyDown={(event) => {
                            for (const hotkey in HOTKEYS) {
                              if (isHotkey(hotkey, event)) {
                                event.preventDefault();
  
                                if (HOTKEYS[hotkey] == "save") {
                                  saveTapped();
                                  return;
                                }
  
  
                                // if (HOTKEYS[hotkey] == "question") {
                                //   questionTapped();
                                //   return;
                                // }
  

                              }
                            }
                          }}
                          />
                        </div>
                      </Slate>
                    </div>
                  </Grid.Column>
                </Grid.Row>
              )}

       
              <Slate
                editor={editor}
                autoFocus
                value={value}
                onChange={(value) => setValue(value)}
              >
                <Grid.Row centered style={{ }}>
                  <Grid.Column style={{}}>
                    <div style={{ maxHeight: "40vh", overflowY: "scroll" }}>

                      <Editable
                      id="bodyEditableSlate"
                        style={{ fontSize: 14 }}
                        renderElement={renderElement}
                        renderLeaf={renderLeaf}
                        placeholder= {isBlockActive(editor, "question") ? '' : 'Type a new note...'}
                        spellCheck
                        autoFocus
                        // onKeyDown={onKeyDown}
                        onKeyDown={(event) => {

                          if (isHotkey("backspace", event)) {
                            // console.log("Current value: " + JSON.stringify(value));

                            if (isBlockActive(editor, "question")) {

                              for (const [node, path] of Editor.nodes(editor, {mode: "lowest"})) {

                                // console.log("\t\tCurrently selection Node: " + JSON.stringify(node) );//+ " , " + JSON.stringify(path));
                                if (Node.string(node).length === 0) {
                                  toggleBlock(editor, "question");
                                }
                              }
                                
                            }
                            else if (isBlockActive(editor, "list-item")) {

                              // If we hit delete and there's nothing in the editor other than
                              // the empty list then we should toggle.

                              const isEmptyExceptForList = (value.map(n => Node.string(n)).join('').length == 0)

                              if (isEmptyExceptForList) {
                                undentItem(editor);

                              }


                            }

                            // console.log("Delete")
                            //event.preventDefault();
                            return;
                          }

                          if (isHotkey("shift+tab", event)) {
                            // attempt to un-indent on shift+tab within list
                            event.preventDefault();
                            undentItem(editor);
                            return;
                          } else if (isHotkey("enter", event)) {
                            // attempt to indent on tab within list

                            if (isMarkActive(editor, "cloze")) {
                              //event.preventDefault();

                              toggleMark(editor, "cloze")

                              return;
                            }

                            const selectedElement = Node.descendant(editor, editor.selection.anchor.path.slice(0, -1));

                            // Replace 'title' with the type of the element which you wish to "break out" from
                            if (selectedElement.type === 'question') {
                              event.preventDefault();
                              const selectedLeaf = Node.descendant(editor, editor.selection.anchor.path);
                          
                              if (selectedLeaf.text.length === editor.selection.anchor.offset) {
                                Transforms.insertNodes(editor, {
                                  type: 'paragraph',
                                  children: [{text: '', marks: []}],
                                });
                              }
                              else {
                                Transforms.splitNodes(editor);
                                Transforms.setNodes(editor, {type: 'paragraph'});
                              }
                            }




                          }

                          for (const hotkey in HOTKEYS) {
                            if (isHotkey(hotkey, event)) {
                              event.preventDefault();

                              // if (HOTKEYS[hotkey] == "cloze") {
                              //   // Determine whether any of the currently selected blocks are code blocks.
                              //   const [match] = Editor.nodes(editor, {
                              //     match: n => n.type === 'cloze',
                              //   })
                              //   // Toggle the block type depending on whether there's already a match.
                              //   Transforms.setNodes(
                              //     editor,
                              //     { type: match ? 'paragraph' : 'cloze' },
                              //     { match: n => Editor.isBlock(editor, n) }
                              //   )
                              //   return;
                              // }


                              if (HOTKEYS[hotkey] == "save") {
                                saveTapped();
                                return;
                              }


                              // if (HOTKEYS[hotkey] == "question") {
                              //   // questionTapped();
                              //   return;
                              // }

                              const mark = HOTKEYS[hotkey];
                              toggleMark(editor, mark);
                            }
                          }
                        }}
                      />



                    </div>
                    {ocrLoading &&
                    <div style={{backgroundColor: "#fff"}}>
                      <Loader style={{backgroundColor: "#fff"}} className="editorOCRLoader" active inline size={"mini"} style={{marginTop: 10}} />
                      </div>
                    }
                  </Grid.Column>
                </Grid.Row>


                
                {(editingCloze) && (
                  <Grid.Row centered style={{ marginTop: 0, marginBottom: 0, borderTop: "1px solid #F1F1F1" , paddingBottom: 0 }}>
                    <Grid.Column width={16} style={{paddingBottom: 0}}>

  


                          <ClozeEditor editingCloze={editingCloze} setEditingCloze={setEditingCloze} updateCloze={updateCloze}/>
     
                    </Grid.Column>
                    </Grid.Row>
                )}


                {((imageAttachmentLoading || uploadedImage) && !editingCloze) && (
                  <Grid.Row centered style={{ marginTop: 0, marginBottom: 0, borderTop: "1px solid #F1F1F1"  }}>
                    <Grid.Column width={16} style={{height: 80}}>

                    {((imageAttachmentLoading && !(uploadedImage && imageDownloadURL)) || (uploadedImage && !imageDownloadURL)) && (
  
                      <div style={{ width: 80, height: 80, display:"flex", flexDirection:"column", justifyContent:"center" }}>
                          <Loader active inline size="large" style={{}} />
                      </div>
     
                )}

                { (uploadedImage && imageDownloadURL && !editingCloze) &&
                

                      <div>
                        <Popup
                          open={imagePopupOpen}
                          onClose={handleImagePopupClose}
                          onOpen={handleImagePopupOpen}
                          wide
                          trigger={
                            <img
                              src={imageDownloadURL}
                              style={{ width: "auto", height: 80 }}
                              ref={imgElement}
                              onLoad={() =>
                                setUploadedImageDimensions({
                                  height: imgElement.current.naturalHeight,
                                  width: imgElement.current.naturalWidth,
                                })
                              } // print 150
                            />
                          }
                          on='click'
                        >
                          <Grid divided columns='equal'>
                            <Grid.Column
                              style={{
                                padding: 0,
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Detector
                                render={({ online }) => (
                                  <Button
                                    onClick={online ? ocrExistingImage : handleOfflineMessage}
                                    style={{
                                      color: "#3F3F3F",
                                      backgroundColor: "#FFF",
                                      textAlign: "center",
                                      width: "100%",
                                      lineHeight: 1.1,
                                    }}
                                    className=''
                                  >
                                    Scan text
                                  </Button>
                                )} />
                            </Grid.Column>
                            <Grid.Column
                              style={{
                                padding: 0,
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Button
                                onClick={removeUploadedImage}
                                style={{
                                  color: "#3F3F3F",
                                  backgroundColor: "#FFF",
                                  textAlign: "center",
                                  width: "100%",
                                  lineHeight: 1.1,
                                }}
                                className=''
                              >
                                Remove image
                          </Button>
                            </Grid.Column>
                          </Grid>
                        </Popup>
                      </div>
}
                    </Grid.Column>
                  </Grid.Row>
                )}

{ (!editingCloze) &&


                <Grid.Row centered style={{display: "flex", justifyContent: "space-between", borderTop: "1px solid #F1F1F1" }}>
                  <div style={{paddingLeft: 16}}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      {/* <Button
                        onClick={open}
                        circular
                        style={{
                          backgroundColor: "#F7F7F7",
                          height: 40,
                          width: 40,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src='/assets/ocr.png'
                          alt='logo'
                          style={{ width: "auto", height: 20 }}
                        />
                      </Button> */}

                      <Button
                        onClick={open}
                        circular
                        style={{
                          backgroundColor: "#F7F7F7",
                          height: 40,
                          width: 40,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: 8,
                        }}
                      >
                        <img
                          src='/assets/image.png'
                          alt='logo'
                          style={{ width: "auto", height: 20 }}
                        />
                      </Button>
                      {/* <Button
                        onClick={questionTapped}
                        circular
                        style={{
                          backgroundColor: "transparent", //"#F7F7F7",
                          height: 40,
                          width: 40,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: 8,
                        }}
                      >
                        <img
                          src='/assets/rt-question.png'
                          alt='logo'
                          style={{ width: "auto", height: 20 }}
                        />
                      </Button> */}

                      {/* <BlockButton format="question" /> */}


                      {/* <OldMarkButton format='cloze'  /> */}
                      <OldMarkButton format='underline'  />

                      <OldMarkButton format='bold'/>
                      <OldMarkButton format='italic' />

                      <BlockButton format="numbered-list"  />
                      <BlockButton format="bulleted-list" />

                    </div>
                  </div>
                  <div style={{paddingRight: 16}}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        active={!isEmpty && !submitting}
                        loading={submitting}
                        className='editorSaveButton'
                        style={{
                          width: 80,
                          height: 40,
                          textAlign: "center",
                          borderRadius: 22,
                          padding: 0,
                          marginTop: 0,
                          marginRight: 0
                        }}
                        size='large'
                        content='Save'
                        onClick={saveTapped}
                      />
                    </div>
                  </div>{" "}
                </Grid.Row>
}
              </Slate>
            </>)}
        </Grid>
    </div>
  );
}

const initialQuestionValue = [
  {
    children: [
      { text: '' },
    ],
  },
]

const initialEmptyValue = [

  {
    type: 'paragraph',
    children: [{ text: '' }],
  },
]


const initialValue = [

  {
    type: 'paragraph',
    children: [{ text: 'Try it out for yourself!' }],
  },
]



const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format)
  const isList = LIST_TYPES.includes(format)

  Transforms.unwrapNodes(editor, {
    match: n => LIST_TYPES.includes(n.type),
    split: true,
  })

  Transforms.setNodes(editor, {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  })

  if (!isActive && isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}

const toggleMark = (editor, format) => {

  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

const isBlockActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: n => n.type === format,
  })

  return !!match
}

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor)
  return marks ? marks[format] === true : false
}



export const CustomElement = props => {
  const { attributes, children, element } = props
  switch (element.type) {

    case 'question':
      return <QuestionElement {...props} />


    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>
    case 'bulleted-list':
      return <ul className="rt-bulleted-list" {...attributes}>{children}</ul>
    case 'heading-one':
      return <h1 {...attributes}>{children}</h1>
    case 'heading-two':
      return <h2 {...attributes}>{children}</h2>
    case 'list-item':
      return <li {...attributes}>{children}</li>
    case 'numbered-list':
      return <ol className="rt-numbered-list" {...attributes}>{children}</ol>
    default:
      return <p {...attributes}>{children}</p>
  }
}

export const CustomLeaf = ({ attributes, children, leaf, text, key, setEditingCloze, context, 
  hideUnderlines, hideAutoUnderlines, showUnderlinesAsAnswer, showAutoUnderlinesAsAnswer }) => {
  //console.log("Rendering custom leaf and context: " + context)

  if (leaf.cloze) {
    children = <ClozeLeaf children={children} attributes={attributes} leaf={leaf} text={text} key={key} setEditingCloze={setEditingCloze}></ClozeLeaf>
  }

  if (leaf.bold) {
    children = <span className="titleTextFont">{children}</span>
    
  }

  if (leaf.code) {
    children = <code>{children}</code>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.underline ) {
    // console.log("Rendering custom leaf--" + text + "--and context: " + context + " , " + hideUnderlines + " , " + showUnderlinesAsAnswer)

    if (context === "flashcardFront" && hideUnderlines) {
        children = <UnderlineClozeLeaf children={children} attributes={attributes} leaf={leaf} text={text} key={key} setEditingCloze={setEditingCloze} spanClass={"underlineQuestion"}></UnderlineClozeLeaf>
    } else if (context === "flashcardBack" && showUnderlinesAsAnswer) {
      children = <UnderlineClozeLeaf children={children} attributes={attributes} leaf={leaf} text={text} key={key} setEditingCloze={setEditingCloze} spanClass={"underlineAnswer"}></UnderlineClozeLeaf>
    } else {
        children = <UnderlineClozeLeaf children={children} attributes={attributes} leaf={leaf} text={text} key={key} setEditingCloze={setEditingCloze} spanClass={"underlineNormal"}></UnderlineClozeLeaf>
    }

  } else if (leaf.autoUnderline) {
    // console.log("Rendering custom leaf--" + text + "--and context: " + context + " , " + hideAutoUnderlines + " , " + showAutoUnderlinesAsAnswer)

    if (context === "flashcardFront" && hideAutoUnderlines) {
        children = <UnderlineClozeLeaf children={children} attributes={attributes} leaf={leaf} text={text} key={key} setEditingCloze={setEditingCloze} spanClass={"underlineQuestion"}></UnderlineClozeLeaf>
    } else if (context === "flashcardBack" && showAutoUnderlinesAsAnswer) {
      children = <UnderlineClozeLeaf children={children} attributes={attributes} leaf={leaf} text={text} key={key} setEditingCloze={setEditingCloze} spanClass={"underlineAnswer"}></UnderlineClozeLeaf>
    }    

  }


  return <span {...attributes}>{children}</span>
}

const OldMarkButton = ({ format, icon }) => {
  const editor = useSlate()

  let isActive = isMarkActive(editor, format)

  return (
    <Button
    className="editorButton"

      active={isActive}
      onMouseDown={event => {
        event.preventDefault()
       // ReactEditor.focus(editor);
        toggleMark(editor, format)

      }}

      circular style={{ height: 40, width: 40, display: "flex", justifyContent: "center", alignItems: "center", marginLeft: 8 }} >

      {/* <Icon>{icon}</Icon> */}
      {/* { format } */}
      <img src={'/assets/rt-' + format + (isActive ? '-g' : '') + '.png'} alt='logo' style={{ width: "auto", height: format == "bold" || format == "italic" ? 19 : 20 }} />

    </Button>
  )
}

const MarkButton = ({ format, icon }) => {
  const editor = useSlate()
  return (
    <Button
    
      className="editorButton"
      active={isMarkActive(editor, format)}
      onMouseDown={event => {
        event.preventDefault()
        toggleMark(editor, format)
        logEvent("Editor Mark Important Tapped")

      }}
      circular style={{ height: 40, width: 40, display: "flex", justifyContent: "center", alignItems: "center", marginLeft: 8 }} >
      <img src='/assets/highlight.png' alt='logo' style={{ width: "auto", height: 20 }} />
    </Button>

  )
}

const UnderlineClozeLeaf = props => {

  const { setEditingCloze, spanClass } = props

  const editor = useSlate()

  async function clozeTapped() {

    const path = ReactEditor.findPath(editor, props.text)

    logEvent("Cloze Editor Opened")
    setEditingCloze(path);
  }

  return (

    // todo, replace underlineclozeleaf with just clozeleaf and see if you can get the functinoality working. Then role back notestableviewcontroller changes and see if you can get the editor working
    // with wrong answer selection + no bugs
    <span className={spanClass}
      //  onDoubleClick={() => clozeTapped()}

      style={{}}
      {...props.attributes}
    >
      {props.children}
    </span>

  )
}

const ClozeLeaf = props => {

  const { setEditingCloze } = props
  
  const editor = useSlate()



  async function clozeTapped() {

    const path = ReactEditor.findPath(editor, props.text)

      logEvent( "Cloze Editor Opened")
      setEditingCloze(path);
  }

  return (


      <span
         onDoubleClick={() => clozeTapped()}
        
    style={{
        borderRadius: 12, backgroundImage: "linear-gradient( 135deg, rgb(239, 65, 54) 0%, rgb(247, 148, 29) 100% )", color: "#fff", marginLeft: 0, marginRight: 0, paddingLeft: 4, paddingRight: 4}}
        {...props.attributes }
           >
        {props.children}
      </span>

  )
}

const BlockButton = ({ format, icon }) => {
  const editor = useSlate()

  var isActive = isBlockActive(editor, format)

  return (
    <Button
     className="editorButton"

      active={isActive}
      onMouseDown={event => {
        event.preventDefault()
        toggleBlock(editor, format)
      }}
      circular style={{height: 40, width: 40, display: "flex", justifyContent: "center", alignItems: "center", marginLeft: 8 }} >

      {/* <Icon>{icon}</Icon> */}
      {/* { format } */}
      <img src={'/assets/rt-' + format + (isActive ? '-g' : '') + '.png'} alt='logo' style={{ width: "auto", height: 20 }} />

    </Button>
  )
}


const QuestionElement = props => {

  return (
    // <pre {...props.attributes}>
    //   <code>{props.children}</code>
    // </pre>

    <div
    className="slate-question"
    style={{
      background: "#FAFAFA",
      color: "#6D6D6D",
      borderRadius: "20px",
      paddingLeft: 10,
      paddingRight: 10,
      paddingTop: 8,
      paddingBottom: 8,
      fontSize: 12,
      border: "1px solid #F1F1F1",
      marginTop: 14,
      marginBottom: 14
    }}
  >

    {props.children}
    </div>    
  )
}



export const withClozes = editor => {
  const { insertData, insertText, isInline , normalizeNode } = editor

  editor.isInline = element => {
    return element.type === 'cloze' ? true : isInline(element)
  }



  editor.normalizeNode = entry => {
    const [node, path] = entry

    if (
      node.text === '' &&
      node.cloze
    ) {
      // ERROR: null is not a valid value for a url
      Transforms.setNodes(editor, { cloze: false }, { at: path })
      return
    }

    if (
      node.cloze
    ) {
      // ERROR: null is not a valid value for a url
      Transforms.setNodes(editor, { bold: false, underline: false, italic: false }, { at: path })
      return
    }


    if (SlateElement.isElement(node) && node.type === 'question') {
     // console.log("Found question node: " + JSON.stringify(path));
      for (const [child, childPath] of Node.children(editor, path)) {

          Transforms.setNodes(editor, { bold: false, underline: false, italic: false, cloze: false }, { at: childPath })

        
      }
    }



    normalizeNode(entry)
  }



  return editor
}




