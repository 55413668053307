import React from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { listenToSelectedTopic } from '../topics/topicActions';
import { listenToTopicFromFirestore, fetchNotesFromFirestore } from '../../app/firestore/firestoreService';
import useFirestoreDoc, { useFirestoreDocs } from '../../app/hooks/useFirestoreDoc';
import { SET_NOTES } from './noteConstants';
import EditorWrapper from '../editor/EditorWrapper';
import NoteList from './NoteList';
import { Container } from 'semantic-ui-react';
import { useCallback } from 'react';
import { sortNotesForTopic } from '../../app/common/util/util';

export default function MainNoteView({ match }) {

    const dispatch = useDispatch();
    const { selectedTopic, selectedTopicId } = useSelector((state) => state.topic);
    const { notes, moreNotes, notesStartDate, notesLastVisible, fetchedNoNotes } = useSelector((state) => state.note);

    const { loading } = useSelector((state) => state.async);
    const { sidebarVisible } = useSelector(state => state.sidebar);
    const { authenticated, currentUserProfile } = useSelector((state) => state.auth);

    function compareByCreatedAt(a, b) {
        return a.created_at - b.created_at;
      }
      

    useFirestoreDocs({
        context: "notes",
        shouldExecute: typeof match.params.id !== 'undefined' && selectedTopic && (selectedTopic.id == match.params.id || selectedTopic.shared_id == match.params.id),
        query: () => fetchNotesFromFirestore(selectedTopic, 200, null),
        data: (snapshotNotes) => dispatch({ type: SET_NOTES, payload: snapshotNotes }),
        deps: [dispatch, selectedTopicId]
    });

    const sortedNotes = notes.sort((a, b) =>  (sortNotesForTopic(a, b, selectedTopic)))

    return (

        <div style={{ display: "flex", width: "100%", flexDirection: "column", justifyContent: "flex-end", height: "100%", minHeight: "100%", paddingTop: 0 }}>

            <div style={{display: "flex", backgroundColor: "", flex: "1 0 auto", paddingTop: 60, marginBottom: (selectedTopic && selectedTopic.shared_id) ? 0 : 8 }}>
                <div style={{ flex: "1 0 auto" }}>
                <div style={{ backgroundColor: "", height: "100%", display: "flex", flexDirection: "column", alignItems: "stretch", position: "relative", overflow: "" }}>

                    <div className={(selectedTopic && selectedTopic.shared_id) ? "sharedNoteList" : "noteList"} style={{ backgroundColor: "", height: "100%", width: "100%", position: "absolute", top: 0, right: 0, display: "flex", flexDirection: "column-reverse", overflow: "overlay" }}>

                        {((selectedTopic && notes && notes.length > 0) || !fetchedNoNotes || (selectedTopic.id != match.params.id && selectedTopic.shared_id != match.params.id)) ?
                            <Container className={sidebarVisible ? "sidebarVisible" : ""} style={{ fontSize: 16, paddingTop: 0, display: "flex", flexDirection: "column-reverse", marginLeft: 16, marginRight: 16 }}>
                                <NoteList
                                    topicId={selectedTopic && selectedTopic.id}
                                    notes={sortedNotes}
                                    loading={loading}
                                    moreNotes={moreNotes}
                                    isHowTo={selectedTopic && selectedTopic.how_to == true}
                                    excluded={selectedTopic && selectedTopic.excluded_notes ? selectedTopic.excluded_notes : []}
                                />
                            </Container>
                            :
                            <Container className={sidebarVisible ? "sidebarVisible" : ""} style={{ height: "100%", fontSize: 15, paddingTop: 0, display: "flex", flexDirection: "column", marginLeft: 16, marginRight: 16, alignItems: "center", justifyContent: "center" }}>
                                <div style={{ textAlign: "center", maxWidth: 420, lineHeight: 1.3 }}>
                                    No notes yet. Type into the text field below type a note, or click the <img src='/assets/image.png' className='bodyInlineImage' style={{ height: 16, width: "auto" }} /> button to attach an image and scan text.
                                </div>
                            </Container>
                        }
                    </div>

                </div>
                </div>
            </div>

            {selectedTopic &&

                <EditorWrapper style={{ flexShrink: 0 }}>

                </EditorWrapper>
            }
        </div>

    );
}
