import React, { useState } from 'react';
import { Grid, Header, Button, Label } from 'semantic-ui-react';
import ImageEditorCropper from './ImageEditorCropper';
import cuid from 'cuid';
import { getFileExtension } from '../../app/common/util/util';
import { uploadToFirebaseStorage } from '../../app/firestore/firebaseService';
import { toast } from 'react-toastify';
import { updateUserProfilePhoto } from '../../app/firestore/firestoreService';
import firebase from '../../app/config/firebase';
import { logEvent, logError } from '../../app/common/logging/logging';
import {Detector} from "react-detect-offline";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import MyTextInput from '../../app/common/form/MyTextInput';
import { useFormikContext, getIn } from 'formik'
import { useEffect } from 'react';

const MonitorValues = ({updateCloze, path}) => {
    // Grab values and submitForm from context
    const { values } = useFormikContext();
    React.useEffect(() => {
        const valuesArray = [values.wrongAnswer1, values.wrongAnswer2, values.wrongAnswer3]

        updateCloze(path, valuesArray.filter((v) =>  v));
    }, [values]);
    return null;
  };

export default function ClozeEditor({updateCloze, editingCloze, setEditingCLoze }) {
   
    // const { values } = useFormikContext()

    // // const wrongAnswer1Value = getIn(values, wrongAnswer1)
    // // const wrongAnswer3Value = getIn(values, wrongAnswer2)
    // // const wrongAnswer3Value = getIn(values, wrongAnswer2)

    // useEffect(() => {
    //     // do something when some field in the form changes
    //     console.log("values changed: " + values)
    // }, [values]) 

    return (
        <div 
        style={{width: "100%" }}>
                <div className="titleTextFont"
                style={{marginBottom: 8,

                    //color: "rgba(0,0,0,.85)",
                    color: "#6D6D6D",
                    fontSize: 12,
                    fontWeight: 700,
                    textTransform: "uppercase"
                
                }}
                
                
                >
                    Wrong answers for this blank
                </div>
        <Formik
            initialValues={{  }}
            validationSchema={Yup.object({

            })}
            onSubmit={async (values, { setSubmitting, setErrors }) => {

                let wrongAnswers =[]
                if (values.wrongAnswer1) {
                    wrongAnswers.push(values.wrongAnswer1);
                }

                if (values.wrongAnswer2) {
                    wrongAnswers.push(values.wrongAnswer2);
                }

                if (values.wrongAnswer3) {
                    wrongAnswers.push(values.wrongAnswer3);
                }

                try {
                    // editedNewCloze(values.answer, wrongAnswers)
                    // setClozePopupOpen(false);

                } catch (error) {


                    setErrors({ auth: error.message });




                    setSubmitting(false);
                }
            }}
        >

            {({ isSubmitting, isValid, dirty, errors }) => (

                <Form className='ui form'>
                    <MonitorValues updateCloze={updateCloze} path={editingCloze}></MonitorValues>
<div style={{display:"flex", flexDirection:"row", justifyContent:"space-between" }}>
                    <MyTextInput autoComplete={"off"} autoFocus name='wrongAnswer1' placeholder={'Wrong Answer #1'}  style={{width: "100%", flexGrow: 1}}/>
                    {errors.auth && <Label basic color='red' style={{ marginBottom: 0 }} content={errors.auth} />}

                    <MyTextInput autoComplete={"off"}  name='wrongAnswer2' placeholder={'Wrong Answer #2'} />
                    {errors.auth && <Label basic color='red' style={{ marginBottom: 0 }} content={errors.auth} />}

                    <MyTextInput autoComplete={"off"}  name='wrongAnswer3' placeholder={'Wrong Answer #3'} />
                    {errors.auth && <Label basic color='red' style={{ marginBottom: 0 }} content={errors.auth} />}
                    </div>
                    {/* <Button className="submitButton"
                        style={{ color: "white", height: 44, borderRadius: 22 }}
                        loading={isSubmitting}
                        disabled={!isValid || !dirty || isSubmitting}
                        type='submit'
                        fluid
                        size='large'
                        content='Save'
                    /> */}

                </Form>
            )}
        </Formik>
        </div>
    );
}
