import React from 'react';
import ModalWrapper from '../../app/common/modals/ModalWrapper';

import { useDispatch, useSelector } from 'react-redux';
import { Grid, Button, Image, Segment } from 'semantic-ui-react';

import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { toast } from 'react-toastify';

import firebase from '../../app/config/firebase';
import { useState } from 'react';
import { logError, logEvent } from '../../app/common/logging/logging';


export default function UpdatePaymentMethodForm({ updateSuccessful, subscriptionId }) {
  const stripe = useStripe();
  const elements = useElements();

  const { currentUserProfile } = useSelector(state => state.profile);

  const [loading, setLoading] = useState(false);

  const CARD_OPTIONS = {
    iconStyle: 'solid',
    style: {
      base: {
        iconColor: '#EF4136',
        color: '#EF4136',
        // fontWeight: 500,
        fontFamily: 'Avenir-Medium, Helvetica',
        fontSize: '14px',
        fontSmoothing: 'antialiased',
        ':-webkit-autofill': {
          color: '#fce883',
        },
        '::placeholder': {
          color: '#888',
        },
      },
      invalid: {
        iconColor: '#ffc7ee',
        color: '#ffc7ee',
      },
    },
  };

  

  const handleSubmit = async (event) => {
    logEvent("Update Payment Method Submitted");

    setLoading(true);

    // Block native form submission.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      setLoading(false);

      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // Use your card Element with other Stripe.js APIs
    const {error, paymentMethod} = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      logError(error);

      toast.error( error.message, {
      });

      setLoading(false);
      return;


    } else {

      const paymentMethodId = paymentMethod.id;      

      try {
        // Call billing portal function
        const functionRef = firebase.app().functions("us-central1").httpsCallable('stripeUpdatePaymentMethod');
        const { data } = await functionRef({ paymentMethodId: paymentMethodId, subscriptionId: subscriptionId });
  
        setLoading(false);

        updateSuccessful(data);
        logEvent("Payment Method Update Succeeded")

        return;

      } catch (error) {
        toast.error(error.message);
        logError(error);

        setLoading(false);
        return;


      }


    }
  };


  return (
    
    <form onSubmit={handleSubmit}>

      <CardElement options={CARD_OPTIONS} style={{ marginTop: 16}} />


              <Button loading={loading} type="submit" disabled={!stripe || !elements} style={{ borderLeft: 0, backgroundColor: "#fff", height: 50, marginTop: 8}}
                className='reviewCompletePrimaryButton normalTextFont' content= "Update" />              
    </form>
  );
}

