import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Segment, Menu, Checkbox, Item, Button, Select, Grid } from 'semantic-ui-react';
import ModalWrapper from '../../app/common/modals/ModalWrapper';
import { closeModal } from '../../app/common/modals/modalReducer';
import { setAutoflashcardsEnabled, setRandomOrder, setNumberOfNotes, setReviewType, fetchReviewingNotes } from './reviewActions';
import { useEffect } from 'react';
import FlashcardFront from './FlashcardFront';
import FlashcardBack from './FlashcardBack';
import MultipleChoiceButton from './MultipleChoiceButton';

export default function MultipleChoiceButtonPanel({ reviewType, showCurrentAnswer, currentNoteIndex, selectedNotes, autoflashcardsEnabled, isHowTo, choices }) {

    const note = selectedNotes[currentNoteIndex]

    return (
        <div style={{display: "flex", flexWrap: "wrap", margin: "-10"}}>
            {choices.map((choice) => (
                <MultipleChoiceButton choice={choice} />
            ))}

        </div>





    )
}