import { SIGN_IN_USER, SIGN_OUT_USER, SET_SUBSCRIBER, SET_STRIPE_SUBSCRIPTIONS, SET_LOADED_REMOTE_CONFIG } from './authConstants';
import {LOCATION_CHANGE} from 'connected-react-router';
import { setAmplitudeUserProperties } from '../../app/common/logging/amplitude';

const initialState = {
  authenticated: false,
  currentUser: null,
  prevLocation: null,
  currentLocation: null,
  subscriberInfo: null,
  isSubscriber: true,
  isSubscriberThroughStripe: null,
  stripeSubscriptions: [],
  loadedRemoteConfig: false

};

export default function authReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_LOADED_REMOTE_CONFIG:
      return {
        ...state,
        loadedRemoteConfig: payload,
      };


    case SIGN_IN_USER:
      return {
        ...state,
        authenticated: true,
        currentUser: {
          emailVerified: payload.emailVerified,
          email: payload.email,
          photoURL: payload.photoURL,
          uid: payload.uid,
          displayName: payload.displayName,
          providerId: payload.providerData[0]?.providerId
        },
      };
    case SIGN_OUT_USER:
      return {
        ...state,
        authenticated: false,
        currentUser: null,
        subscriberInfo: null,
        isSubscriberThroughStripe: null,
        stripeSubscriptions: []
      };
      case SET_SUBSCRIBER:

        let isSubscriber = false
        let isSubscriberThroughStripe = false

        if (payload.entitlements && payload.entitlements.subscription) {

            if (!payload.entitlements.subscription.expires_date || (new Date(payload.entitlements.subscription.expires_date) > new Date())) {
              let expiresDate = new Date(payload.entitlements.subscription.expires_date)

              isSubscriber = true
            }

            let productIdentifier = payload.entitlements.subscription.product_identifier

            if (payload.subscriptions && payload.subscriptions[productIdentifier] && payload.subscriptions[productIdentifier].store == "stripe") {
              isSubscriberThroughStripe = true
            }

          }

        setAmplitudeUserProperties({
          subscribed: isSubscriber,
          subscribed_through_stripe: isSubscriberThroughStripe })

        return {
          ...state,
          subscriberInfo: payload,
          isSubscriber: isSubscriber,
          isSubscriberThroughStripe: isSubscriberThroughStripe
        };     
      case SET_STRIPE_SUBSCRIPTIONS:
        return {
          ...state,
          stripeSubscriptions: payload,

        };           
      case LOCATION_CHANGE:
        return {
          ...state,
          prevLocation: state.currentLocation,
          currentLocation: payload.location
        }
    default:
      return state;
  }
}
