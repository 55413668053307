import React from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import LogoLottie from './LogoLottie';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export default function LoadingComponent({inverted = true, content = 'Loading...'}) {

  const history = useHistory();

  const { userHistory, newUserHistory } = useSelector((state) => state.onboarding);

  // This makes it so that old tabs,
  // on a sign in, get pushed to the root
  useEffect(() => {
    if (newUserHistory && userHistory && userHistory.length > 1) {
      history.push("/")
    }

    return () => {

    }
  }, [history, userHistory, newUserHistory]);


    return (
        <Dimmer inverted={inverted} active={true}>
            <LogoLottie></LogoLottie>
        </Dimmer>
    )
}