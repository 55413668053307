
import { SET_ONBOARDING_NEEDED, ADD_TO_USER_HISTORY } from './onboardingConstants';
import { useDispatch } from 'react-redux';
  
  const initialState = {
    onboardingNeeded: false,
    userHistory: [],
    newUserHistory: null
  };
  
  export default function onboardingReducer(
    state = initialState,
    { type, payload }
  ) {
    switch (type) {
      case SET_ONBOARDING_NEEDED:
        return {
          ...state,
          onboardingNeeded: payload,
        };

        case ADD_TO_USER_HISTORY:

          return {
            ...state,
            userHistory: state.userHistory.concat([payload]),
            newUserHistory: payload

          };        

      default: {
        return state;
      }
    }
  }
  