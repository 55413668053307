import React, { useState } from "react";

import { Button, Confirm, Container, Dropdown, Grid, Popup, Image, Form, Label, Divider } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import ModalWrapper from "../../app/common/modals/ModalWrapper";
import { Link, useHistory } from "react-router-dom";
import { deleteNoteInFirestore, updateExcludeInFirestore, addFolderToTopic, removeFolderFromTopic, updateTopicAttributes, setNeedsWelcomeInFirestore } from "../../app/firestore/firestoreService";
import { toast } from "react-toastify";
import { closeModal, openModal } from "../../app/common/modals/modalReducer";
import { Formik } from "formik";
import * as Yup from 'yup';
import firebase, { remoteConfig } from '../../app/config/firebase';
import { updateUserEmail, registerInFirebaseAnonyously } from '../../app/firestore/firebaseService';
import MyTextInput from "../../app/common/form/MyTextInput";
import { logEvent } from "../../app/common/logging/logging";
import ReviewCarousel from "./ReviewCarousel";
import { useEffect } from "react";

export default function WelcomeModal() {

    const dispatch = useDispatch();

    const { selectedTopic } = useSelector((state) => state.topic);
    const history = useHistory();

    // If you only want to run the function given to useEffect after the initial render, you can give it an empty array as second argument.  
    useEffect(() => {
        setNeedsWelcomeInFirestore(false);
        logEvent("Opening View Seen")

    }, []);


    async function showSubWallIfAppropriate() {
        if (remoteConfig().getValue('showSubscribeViewOnWelcome').asBoolean()) {

            dispatch(openModal({ modalType: 'SubscribeWall', modalProps: { initialSubscribeView: true } }))

        }
    }


    async function getStarted() {

        showSubWallIfAppropriate();
        logEvent("Opening Done Tapped")
    }


    async function signIn() {


        dispatch(closeModal());
        dispatch(openModal({ modalType: 'LoginForm', modalProps: { notClosable: false } }))
        logEvent("Opening Login Tapped")

    }


    return (
        <ModalWrapper onClose={getStarted}
            size="" notClosable={false} style={{ borderRadius: 50 }} maxWidth={((remoteConfig().getValue('showReviewModuleInWelcome').asBoolean()) ) ? null : 420} contentPadding={0}>

<Grid className="WelcomeStackableGrid" divided='vertically' divided  stackable  style={{margin: "0"}}>
    <Grid.Row columns={((remoteConfig().getValue('showReviewModuleInWelcome').asBoolean()) ) ? 2 : 1} style={{}}>
    <Grid.Column className="WelcomeStackableGridColumn" style={{}}>

             <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center",borderRadius: 50 }}> 
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>

                    <div className="titleTextFont" style={{ display: "flex", alignItems: "center", fontSize: 22 }}>


                        {/* <div  style={{ display: "flex", alignItems: "center" }}>

                            <img
                                src='/assets/textLogo.png'
                                alt='logo'
                                style={{ width: "auto", height: 24 }}
                            />
                        </div> */}

                        <div style={{ display: "flex", alignItems: "center", paddingBottom: 5 }}>
                            <Image inline
                                src='/assets/logo.png'
                                style={{ height: "auto", width: 24 }}
                            />
                        </div>
                        <div style={{ marginLeft: 10 }} class="logoTextFont">NOTEFUEL

                            </div>
                    </div>
                    <div className="normalTextFont" style={{
                        fontSize: 15, color: "#3F3F3F", lineHeight: "1.4285em",
                        fontWeight: "400", textAlign: "center"
                    }}>
                        <a onClick={signIn} style={{ color: "#EF4136", cursor: "pointer" }}>Sign in</a>
                    </div>


                </div>

                <div className="titleTextFont" style={{
                    paddingTop: 40, fontSize: 27, lineHeight: "1.16", color: "#3F3F3F", 
                    fontWeight: "400", textAlign: "center"
                }}> 

                {remoteConfig().getValue('webOpeningTitleText').asString() ?
                remoteConfig().getValue('webOpeningTitleText').asString() :
              <> The best note-taking app for&nbsp;learning </>
            
            } 



                    {/* {remoteConfig().getValue('webOpeningSubtitleText').asString()} */}
                    {/* <br></br>
                    <br></br> */}


                </div>

                <div className="normalTextFont" style={{
                    paddingTop: 8, fontSize: 17, color: "#3F3F3F", lineHeight: "1.4",
                    fontWeight: "400", textAlign: "center", width: "100%"
                }}> 

                        {remoteConfig().getValue('webOpeningSubtitleText').asString()} 

                </div>

                {/* <Formik
                    initialValues={{ email: '' }}
                    validationSchema={Yup.object({
                        email: Yup.string().required().email()
                    })}
                    onSubmit={async (values, { setSubmitting, setErrors }) => {
                        try {

                            // If the user
                            if (values.email) {
                                // Create an account with email
                                await registerInFirebaseAn(values.email);

                            } else {
                                // Create an accoutn anonymously.
                                await registerInFirebaseAnonymously();

                            }
                            setSubmitting(false);
                            dispatch(closeModal());
                        } catch (error) {

                            // if (error.code === "auth/requires-recent-login") {
                            //     toast.error(error.message);

                            // } else {
                            //     setErrors({auth: 'Email is invalid.'});
                            // }
                            setErrors({ auth: error.message });

                            setSubmitting(false);
                        }
                    }}
                >

                    {({ isSubmitting, isValid, dirty, errors }) => (
                        <Form className='ui form' style={{width: "100%", paddingTop: 20}}>
                            <div>
                                Providing email is optional - for account recovery and cross-device sync. Use Notefuel anonymously if you want!)
                            </div>
                            <MyTextInput autoFocus name='email' placeholder={'Email Address'} />
                            {errors.auth && <Label basic color='red' style={{ marginBottom: 10 }} content={errors.auth} />}
                            <Button className="secondaryButton"
                                style={{ backgroundColor: "#fff", height: 50, borderRadius: 25, width: 200, boxShadow: "0 5px 10px 0 rgba(0,0,0,0.10)" }}
                                loading={isSubmitting}
                                disabled={!isValid || !dirty || isSubmitting}
                                type='submit'
                                fluid
                                size='large'
                                content='Get started'
                            />

                        </Form>
                    )}
                </Formik> */}

                <div className="normalTextFont" style={{
                    paddingTop: 32, fontSize: 16, color: "#3F3F3F", lineHeight: "1.4285em",
                    fontWeight: "400"
                }}>
                    <Button
                        onClick={getStarted}
                        style={{ color: "#fff", height: 50, borderRadius: 25, width: 254, fontSize: 16,marginTop: 0 }}
                        className='submitButton'
                    >
                        <div>Get started</div>

                    </Button>
                </div>
                <div className="normalTextFont" style={{
                    paddingTop: 16, fontSize: 13, color: "#3F3F3F", lineHeight: "1.4285em",
                    fontWeight: "400", textAlign: "center", color: "#6D6D6D", width: 254
                }}>

You're already signed into a fresh account - use Notefuel instantly & anonymously!

                    {/* You're already signed into an anonymous account so you can try Notefuel instantly.  

                    <span className="normalTextFont"> Have an existing account? <a onClick={signIn} style={{ color: "#EF4136", cursor: "pointer", fontSize: 14 }}> Sign in</a>
                    </span> */}
                </div>

                </div>

                </Grid.Column>

                {((remoteConfig().getValue('showReviewModuleInWelcome').asBoolean()) ) &&
    <Grid.Column className="WelcomeStackableGridColumn reviewsCarouselColumn" style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>

                    <>
                    {(remoteConfig().getValue('showReviewsExplanationLabel').asBoolean()) && 
                        <div className="normalTextFont" style={{ color: "#6D6D6D", fontSize: 17, textAlign: "center", width: "100%" }}>
                            Here's what our users say:

                            

                    </div>}
                        <ReviewCarousel></ReviewCarousel>
                    </>
                    </Grid.Column>

                }

    </Grid.Row>
  </Grid>

            {/* </div> */}


        </ModalWrapper>
    );
}
