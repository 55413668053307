import React from 'react';
import { Container, Header, Image, Button, Icon } from 'semantic-ui-react';
import SpecialPageNavBar from './SpecialPageNavBar';
import {Helmet} from "react-helmet";

export default function Privacy() {
    return (
        <>
            <Helmet>
                <title>Privacy Policy | Notefuel</title>
            </Helmet>
            <SpecialPageNavBar></SpecialPageNavBar>
            <Container style={{paddingTop: 80, paddingBottom: 20}} className={"specialPage"}>
                <h1 style={{textAlign: "center", marginBottom: 20}}>Privacy Policy</h1>


                <h3>Our Commitment To Privacy</h3>

                <p>Your privacy is important to us. To better protect your privacy, we provide this notice explaining our online information practices and the choices you can make about the way your information is collected and used.</p>

                <h3>The Information We Collect</h3>

                <p>This notice applies to all information collected or submitted on the Notefuel website, Notefuel email, and the Notefuel applications and services for iPhone, iPad, Android, Kindle, and other devices and platforms, hereafter referred to as simply Notefuel.
Notefuel occasionally needs to collect information for convenience, practicality, or technical requirements. This information includes:</p>

                <p>Notefuel occasionally needs to collect information for convenience, practicality, or technical requirements. This information includes your email address, so that you can log into Notefuel from multiple computers, as well as any notes that you input into Notefuel.</p>


                <p>Like most Web sites, Notefuel also collects and stores information automatically and through the use of electronic tools that may be transparent to our visitors. For example, we may log the name of your Internet Service Provider, your IP address, and information provided by and about your Internet browser. As we adopt additional technology, we may also gather similar information through other means.</p>

                <p>We may also use "cookie" technology. Among other things, we may use cookies or other tracking technology for the purposes of keeping you logged into our website to spare you from having to re-authenticate each time you visit.</p>

                <h3>The Way We Use Information</h3>

                <p>We use the information you provide to deliver Notefuel's features. We do not share this information with outside parties except to the extent necessary to accomplish Notefuel's functionality.</p>

                <p>We use your email addresses to send you the Notefuel email, to send periodic updates regarding Notefuel’s features, and to answer the email we receive. Such addresses are not used for any other purpose and are not shared with outside parties.</p>

                <p>We may disclose your information in response to subpoenas, court orders, or other legal process, or to establish or exercise our legal rights or to defend against legal claims.</p>

                <p>We may disclose your information when we believe it necessary or desirable in order to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, violations of our policies, and/or to protect our rights and property.</p>

                <p>In the future, we may sell, buy, merge, or partner with other companies or businesses. In such transactions, user information may be among the transferred assets.</p>

                <h3>Publicly Accessible Information</h3>

                <p>In the future, Notefuel may contain social and sharing
                functionality, including the ability to allow others to views your notes if you reveal or share their associated URLs.
</p>

                <h3>External Services</h3>

                <p>External services beyond Notefuel's control are integrated with Notefuel where necessary, such as <a href="https://mixpanel.com">Mixpanel</a> and <a href="https://analytics.google.com">Google Analytics</a> for traffic measurement and tracking.</p>

                <p>Google Analytics and Google Adwords: Google’s ability to use and share information collected by Google Analytics is restricted by the Google Analytics Terms of Service and the <a href="https://policies.google.com/privacy">Google Privacy Policy</a>. You may also learn more about how Google collects and processes data specifically in connection with Google Analytics. You may prevent your data from being used by Google Analytics by downloading and installing the <a href="https://tools.google.com/dlpage/gaoptout">Google Analytics Opt-out Browser Add-on</a>.</p>

                <p>Mixpanel: Mixpanel’s use of information collected through Mixpanel is set forth in the “Privacy Policy” section of its <a href="https://mixpanel.com/legal/terms-of-use/">Terms of Use</a>. You may opt out of Mixpanel’s automatic retention.</p>
                <p>Notefuel makes reasonable efforts to ensure that all integrated external services are trustworthy and reputable, but Notefuel cannot be held responsible for data collection and usage by such services.</p>

                <h3>Our Commitment To Data Security</h3>

                <p>To prevent unauthorized access, maintain data accuracy, and ensure the correct use of information, we have put in place appropriate physical, electronic, and managerial procedures to safeguard and secure the information we collect online.</p>

                <h3>Our Commitment To Children's Privacy</h3>

                <p>Protecting the privacy of the very young is especially important. For that reason, we never collect or maintain information at our website from those we actually know are under 13, and no part of our website is structured to attract anyone under 13.</p>

                <h3>How You Can Access Or Correct Your Information</h3>

                <p>You can access personally identifiable information
                that we collect online and maintain by logging into your Notefuel account within
the Notefuel app.</p>

                <p>You can correct factual errors in your personally
                identifiable information by changing or deleting the erroneous information, by
emailing us at&nbsp;<a href="mailto:support@notefuel.com?subject=Changing my Notefuel information">support@notefuel.com</a>.</p>

                <p>To protect your privacy and security, we will also take
                reasonable steps to verify your identity before granting access or making
corrections.</p>

                <h3>Deleting Information</h3>

                <p>You can delete your entire Notefuel account at any time by
by emailing us at&nbsp;<a href="mailto:support@notefuel.com?subject=Deleting my Notefuel account">support@notefuel.com</a>.
If you delete your account, all account information and saved page data is
deleted from the Notefuel service within 24 hours.</p>

                <p>Due to the nature of backups and logs, deleted data may
persist in backups and logs until they are deleted.</p>

                <h3>How To Contact Us</h3>

                <p>Should you have other questions or concerns about these
privacy policies, please email&nbsp;<a href="mailto:support@notefuel.com">support@notefuel.com</a>.
At this time, we only have the ability to receive and send English-language
communications.</p>

                <h3>Changes To This Policy</h3>

                <p>Notefuel reserves the right to change this policy at any
                time. Please check this page periodically for changes. Your continued use of Notefuel
                following the posting of changes to this policy will mean you accept those
changes.</p>

                <p style={{paddingTop: 16, fontSize: 13, color: "#3F3F3F"}}>Last updated: 8/5/2019</p>

            </Container>
        </>
    )
}