import React from 'react';
import { Container, Header, Image, Button, Icon } from 'semantic-ui-react';
import SpecialPageNavBar from './SpecialPageNavBar';

export default function Kindle() {
    return (
        <>
            <SpecialPageNavBar></SpecialPageNavBar>
            <Container style={{ paddingTop: 80, paddingBottom: 20, fontSize: 15 }} className={"specialPage"}>

                <h1 style={{ textAlign: "center", marginBottom: 20 }}>Importing Kindle highlights & notes</h1>

                <div><span style={{ textStyle: "italic" }}>Importing your Kindle highlights & notes only takes a few seconds!
Just follow the instructions below, or watch our short walkthrough video.</span></div>

                        <h2>Walkthrough video (46 seconds)</h2>

                        <div>
                            <div style={{ marginTop: 18, width: "100%" }}>
                                <div style={{maxWidth: "300"}}>

                                    <iframe className="youtube-player" type="text/html" width="324" height="576"
                                    src="https://www.youtube.com/embed/GbdIE6UR2FI?version=3&amp;rel=1&amp;fs=1&amp;autohide=2&amp;showsearch=0&amp;showinfo=1&amp;iv_load_policy=1&amp;wmode=transparent"
                                    allowFullScreen style={{border: "0"}}></iframe>

                                </div>
                            </div>
                        </div>

                        <h2>Detailed instructions</h2>
                        <ol>
                            <li>Select a book within the Kindle iOS app, and open its "notebook" by
    tapping on the paper icon in the top right of your screen.</li>
                            <li>From the notebook view, tap on the export button in the top right of the screen and choose "Email".
    Then, select "None" as the citation style. Complete the export by sending an email to yourself.</li>
                            <li>Open your email app, and tap on the attachment that has been emailed to you by Kindle.</li>
                            <li>Finally, share the attachment to the Notefuel app by tapping on the share icon,
    and then the Notefuel icon (it will say "Copy to Notefuel").</li>
                        </ol>

            </Container>
        </>
    )
}