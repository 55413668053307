import React from 'react';
import { Menu, Container, Label } from 'semantic-ui-react';
import { NavLink, useHistory, Link } from 'react-router-dom';
import SignedOutMenu from './SignedOutMenu';
import SignedInMenu from './SignedInMenu';
import { useSelector, useDispatch } from 'react-redux';
import { openSidebar, closeSidebar } from '../sidebar/sidebarReducer';
import TopicTitle from './TopicTitle';
import { openModal } from '../../app/common/modals/modalReducer';
import { overdueCount } from '../../app/common/util/util';
import { logEvent } from '../../app/common/logging/logging';
import firebase from '../../app/config/firebase';

export default function NavBar({ setFormOpen, match }) {
  const { authenticated } = useSelector(state => state.auth);
  const { sidebarVisible } = useSelector(state => state.sidebar);
  const { selectedTopic } = useSelector((state) => state.topic);

  const history = useHistory();

  const dispatch = useDispatch();

  let overdue = selectedTopic ? overdueCount(selectedTopic) : null

  async function toggleMenu(event) {
    // firebase.functions().useEmulator("localhost", 5001);

    // try {
    //   const functionRef = firebase.app().functions("us-central1").httpsCallable('getSearchKeyCallable');
    //   const { data } = await functionRef({});
    //   console.log("Data from called function: ", data)
    // }  catch(error) {
    //   console.error("Error processing topic: ", error);
    //   return null;
    // }

    // return;

    event.preventDefault();
    event.stopPropagation();
    sidebarVisible ? dispatch(closeSidebar()) : dispatch(openSidebar())
    logEvent("Toggle Menu", {sidebar_will_be_visible: !sidebarVisible})

  }

  return (
    <Menu inverted fixed='top' style={{height: 60}} className={sidebarVisible ? "sidebarVisible" : "sidebarHidden "} >
      <Menu.Item onClick={toggleMenu} style={{ borderLeft: 0, position: "absolute", height: "100%", zIndex: 1000 }}>
        <img src='/assets/menu.png' alt='logo' style={{ height: 15, width: "auto" }} />
      </Menu.Item>
      <Container style={{fontSize: 16, display: "flex", flexDirection: "row", justifyContent: "space-between"}} className={sidebarVisible ? "sidebarVisible" : "sidebarHidden"}>


        <TopicTitle/>

        { selectedTopic &&
        <Menu.Item id="reviewButton" onClick={() => {logEvent("Note List Refuel Button Tapped", {topic_id: selectedTopic.id})}}  as={Link} to={`/t/${selectedTopic.id}/review`}
            style={{ paddingTop: 10, paddingBottom: 10 }}>
          <div className="logoWrapper">

          {(selectedTopic.auto_spaced_repetition && overdue > 0) &&
        <Label id="overdueNavBarCount" style={{ color: "white", position: "absolute",
        borderRadius: 12, height: 20, backgroundImage: "linear-gradient( 135deg, rgb(239, 65, 54) 0%, rgb(247, 148, 29) 100% )",
        display: "flex",  flexDirection: "column", justifyContent: "center", paddingLeft: 8, paddingRight: 8 }}>
          {overdue}
          </Label>
      }
            <img src='/assets/logo.png' alt='logo' style={{ width: 22, height: 22 }} />

          </div>
        </Menu.Item>
        }
      </Container>
    </Menu>
  );
}
