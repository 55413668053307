import React from 'react';

import { useEffect } from 'react';
import { useState } from 'react';
import firebase from '../../app/config/firebase';

export default function NoteImageAttachment({ note }) {


  const [imageDownloadURL, setImageDownloadURL] = useState(null);

  useEffect(() => {

    if (note.image_attachment_full_path) {
      const storageRef = firebase.storage().ref();
      storageRef.child(note.image_attachment_full_path).getDownloadURL().then(downloadURL => {
        setImageDownloadURL(downloadURL);
      })
    }

    return () => {}
  }, [note]);

  var imageAttachmentWidth =  note.image_attachment_width

  if (!imageAttachmentWidth) {
    imageAttachmentWidth = 300;
  }

  return (

    <div style={{ maxWidth: 600 }}>
      <img alt="" src={imageDownloadURL}
        style={{ width: imageAttachmentWidth, maxWidth: "100%", height: "auto" }}>

      </img>
    </div>

  );






}
