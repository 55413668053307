import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/remote-config'
import 'firebase/analytics'
import { consoleLog } from '../common/logging/logging';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
}

consoleLog("Using firebase: " + process.env.REACT_APP_FIREBASE_AUTH_DOMAIN)
consoleLog("Using environemnt: " + process.env.NODE_ENV)
consoleLog("Using production firebase: " + process.env.REACT_APP_USING_PRODUCTION_FIREBASE)

firebase.initializeApp(firebaseConfig);

firebase.firestore().settings({
  cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED
});
  
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)

firebase.firestore();

firebase.firestore().enablePersistence({synchronizeTabs: false})
  .catch(function(err) {
      if (err.code == 'failed-precondition') {
          // Multiple tabs open, persistence can only be enabled
          // in one tab at a a time.
          // ...
      } else if (err.code == 'unimplemented') {
          // The current browser does not support all of the
          // features required to enable persistence
          // ...
      }
  });

//firebase.functions().useEmulator("localhost", 5001);
//firebase.functions().useFunctionsEmulator('http://localhost:5001');


export default firebase;

const firebaseAnalytics = firebase.analytics();

export function analytics() {
  return firebaseAnalytics;
}

const firebaseRemoteConfig = firebase.remoteConfig();
firebaseRemoteConfig.settings = {
    fetchTimeMillis: 2000, // One second before timing out while fetching
};

const commonDefaultConfig = {
  webOpeningTitleText:  "",
  webOpeningSubtitleText:  "Notefuel helps you absorb and retain knowledge.",
  showSubscribeViewOnWelcome: true,
  showReviewModuleInWelcome: true,
  showPremiumButtonOnWebSidebar: true,
  showReviewsExplanationLabel: true,

}

// Production topics
if (process.env.REACT_APP_USING_PRODUCTION_FIREBASE == "true") {
  consoleLog("Using prod firebase")
  firebaseRemoteConfig.defaultConfig = ({
    ...commonDefaultConfig,
    'sharedHowToTopic': 'Uus2faTdYHdhLdC7z03o',
    'sharedExampleTopics': '09ROf6kGvbMHlBHStUXR,x8eaYaNzCF9wT5niEU82',
  
  });
} else {

  consoleLog("Using dev firebase")

  firebaseRemoteConfig.defaultConfig = ({
    ...commonDefaultConfig,

    'sharedHowToTopic': '2ys9f4INInOnHSmRvEwL',
    'sharedExampleTopics': '04Y4UEhBWMevKx3fcUOK,03uU0oHU2dMcYwb6wQaX'
  });

}


export function remoteConfig() {
    return firebaseRemoteConfig;
}
