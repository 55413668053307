import React from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'semantic-ui-react';
import { closeModal } from './modalReducer';

export default function ModalWrapper({children, size, header, className, notClosable, maxWidth, onClose, contentPadding}) {
    const dispatch = useDispatch();

    function handleOnClose() {
        dispatch(closeModal());

        if (onClose) {
            onClose();
        }
    }

    return (
     <Modal closeOnDimmerClick={!notClosable}  className={className} open={true} onClose={handleOnClose} size={size }
        style={{borderRadius: 10, maxWidth: maxWidth ?? null}} >

            {header && <Modal.Header style={{borderTopLeftRadius: 20, borderTopRightRadius: 20, padding: "0px !important"}}>{header}</Modal.Header>}
            <Modal.Content  style={{borderRadius: 20, padding: contentPadding ?? 20}}>
                {children}
            </Modal.Content>
        </Modal>
    )
}