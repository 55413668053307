import React from 'react';
import ModalWrapper from '../../app/common/modals/ModalWrapper';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import MyTextInput from '../../app/common/form/MyTextInput';
import { Button, Label } from 'semantic-ui-react';
import { useDispatch } from 'react-redux';
import { closeModal } from '../../app/common/modals/modalReducer';
import { updateUserEmail, registerInFirebase, sendVerificationEmail, sendSignInLink } from '../../app/firestore/firebaseService';
import firebase from '../../app/config/firebase';
import { logEvent } from '../../app/common/logging/logging';
import { toast } from 'react-toastify';

export default function ChangeEmailForm() {
    const dispatch = useDispatch();

    return (
        <ModalWrapper size='mini' header='Update your email'>
            <Formik
                initialValues={{ email: firebase.auth().currentUser.email ?? '' }}
                validationSchema={Yup.object({
                    email: Yup.string().required().email()
                })}
                onSubmit={async (values, { setSubmitting, setErrors }) => {
                    try {
                        await updateUserEmail(values);
                        setSubmitting(false);
                        dispatch(closeModal());
                        logEvent("Settings Change Email Submitted")
                        sendVerificationEmail();
                    } catch (error) {

                        if (error.code === "auth/requires-recent-login") {
                        //   toast.error("Just be safe, we need you to reauthenticate your current email before changing it. Check your " 
                        //   + firebase.auth().currentUser.email + " email and click on the link we just sent you.");
                          sendSignInLink(firebase.auth().currentUser.email);
                          setErrors({ auth: "Just be safe, we need you to reauthenticate your current email before changing it. Check your " 
                          + firebase.auth().currentUser.email + " email and click on the link we just sent you." });

                        } else {
                            setErrors({ auth: error.message });
                        }



                        setSubmitting(false);
                    }
                }}
            >

                {({ isSubmitting, isValid, dirty, errors }) => (
                    <Form className='ui form'>
                        <MyTextInput autoFocus name='email' placeholder={firebase.auth().currentUser.email ?? 'Email Address'} />
                        {errors.auth && <Label basic color='red' style={{ marginBottom: 10 }} content={errors.auth} />}
                        <Button className="submitButton"
                            style={{ color: "white", height: 44, borderRadius: 22 }}
                            loading={isSubmitting}
                            disabled={!isValid || !dirty || isSubmitting}
                            type='submit'
                            fluid
                            size='large'
                            content='Save'
                        />

                    </Form>
                )}
            </Formik>
        </ModalWrapper>
    )
}