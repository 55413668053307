import React, { useState } from "react";

import { Button, Confirm, Container, Dropdown, Grid, Popup, Image, Form, Label, Divider } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import ModalWrapper from "../../app/common/modals/ModalWrapper";
import { Link, useHistory } from "react-router-dom";
import { deleteNoteInFirestore, updateExcludeInFirestore, addFolderToTopic, removeFolderFromTopic, updateTopicAttributes } from "../../app/firestore/firestoreService";
import { toast } from "react-toastify";
import { closeModal, openModal } from "../../app/common/modals/modalReducer";
import { Formik } from "formik";
import * as Yup from 'yup';
import firebase, { remoteConfig } from '../../app/config/firebase';
import { updateUserEmail, registerInFirebaseAnonyously } from '../../app/firestore/firebaseService';
import MyTextInput from "../../app/common/form/MyTextInput";
import { logEvent } from "../../app/common/logging/logging";

export default function ReviewCarouselItem({ review }) {

    const dispatch = useDispatch();

    return (



        <div style={{ color: "#3F3F3F", paddingTop: 16, fontSize: 15, marginTop: 0, backgroundColor: "#f8f8f8", padding: 16 }}>
            <div>
                <span> <img src='/assets/star.png' className='reviewStar' /></span>
                <span> <img src='/assets/star.png' className='reviewStar' /></span>
                <span> <img src='/assets/star.png' className='reviewStar' /></span>
                <span> <img src='/assets/star.png' className='reviewStar' /></span>
                <span> <img src='/assets/star.png' className='reviewStar' /></span>
            </div>
            <div style={{ paddingTop: 4, paddingBottom: 16, fontSize: 13 }} className="normalTextFont">
                {review.userName}, {review.date}
            </div>
            <div style={{ paddingTop: 4, paddingBottom: 4, fontSize: 15 }} className="titleTextFont">
                {review.title}
            </div>
            {review.text}
        </div>


    );
}
